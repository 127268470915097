<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
	<div class="page-content" v-if="productList.length >= 1">
		<h1>RELATED PRODUCTS</h1>
		<div>
			<swiper ref="mySwiper" class="news-swiper" :options="swiperOptions">
				<swiper-slide class="news-holder" v-for="item in relatedList" :key="item.id">
					<productItem :items="item"> </productItem>
				</swiper-slide>
			</swiper>
		</div>

		<div class="swiper-button-prev"></div>
		<!--左箭头。如果放置在swiper外面，需要自定义样式。-->
		<div class="swiper-button-next"></div>
		<!--右箭头。如果放置在swiper外面，需要自定义样式。-->
	</div>

	<!--
    <div class="item-card">

        <productItem :items="item" v-for="item in itemss" :key="item.id">
        </productItem>

    </div> -->
</template>
<style scoped>
/* 手机屏幕小于等于768px */
@media only screen and (max-width: 768px) {
	.page-content {
		padding: 10px;
		width: auto !important;
		margin-bottom: 0 !important;
	}

	.news-swiper {
		width: 85% !important;
	}
}
</style>
<script>
// @ is an alias to /src
import productItem from "../product/productItem.vue";

export default {
	name: "relatedProducts",
	props: {
		productList: Array,
	},
	components: { productItem },
	data() {
		return {
			relatedList: this.productList,
			swiperOptions: {
				slidesPerView: 3,
				loop: true,
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
			},
			activeIndex: 1,
		};
	},
	watch: {
		productList(val) {
			this.relatedList = val;
		},
	}, updated() {
		// 在每次组件更新后，重新调整Swiper
		this.handleWindowResize();
	},
	methods: {
		gotoDeatil(item) {
			this.$router.push({ name: "ProductDetail", params: { id: item.id } });
		},
		handleWindowResize() {
			// 获取当前窗口宽度
			const windowWidth = window.innerWidth;
			// 根据窗口宽度设置 slidesPerView
			if (this.$refs.mySwiper) {
				if (windowWidth < 768) {
					this.$refs.mySwiper.swiperInstance.params.slidesPerView = 2;
				} else {
					this.$refs.mySwiper.swiperInstance.params.slidesPerView = 3;
				}
				this.$refs.mySwiper.swiperInstance.update();
			}
		},
	},

	computed: {},
	mounted() {
		window.addEventListener("resize", this.handleWindowResize);
		this.$nextTick(() => {
			this.handleWindowResize();
		});
	},
	created() { },
};
</script>
