<template>
	<el-container>
		<el-main class="section-page home-product">
			<div class="page ">
				<div class="bg-big-txt">PRODUCT</div>

				<div class="section-inner page-product">
					<el-row :gutter="10">
						<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
							<div class="section-parts">
								<!-- <img src="../../assets/product_main.jpg" class="section-pic" /> -->
								<img :src="datas.product_pic" class="section-pic" />
							</div>
						</el-col>
						<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
							<div class="section-parts">
								<h1 v-html="datas.product_title"></h1>
								<h4 v-html="datas.product_text">

								</h4>

								<div class="btn-more-reverse" @click="goto()">LEARN MORE ></div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>


		</el-main>
	</el-container>
</template>
<style scoped>
.section-pic {
	width: 630px;
}

.btn-more-reverse {
	margin-top: 150px;
}

@import "../../common//flexible/home_product.css";
</style>
<script>
export default {
	name: "productHolder",
	props: {
		datas: {
			type: Object,
			required: true,
		},},
	data() {
		return {};
	},
	methods: {
		// topre() {
		// 	this.swiper.slidePrev();
		// },
		// tonext() {
		// 	this.swiper.slideNext();
		// },
		goto() {
			this.$router.push({ name: "Product", params: { cate: "FOOD" } });
		},
	},
	components: {},
	computed: {
		// swiper() {
		// 	return this.$refs.mySwiper.$swiper;
		// },
	},
	mounted() { },
};
</script>