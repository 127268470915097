<template>
	<div class="helper-holder"  @click="handleClick">
		<div class="helper-mini">Send Message</div>
	</div>
</template>

<style scoped>
.helper-mini {
	font-size: 12px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: white;
	line-height: 20px;
	display: flex;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: center;
	height: 100%;
	align-items: center;
	width: 100%;
	border-radius: 50%;
	background: #2A8FCA;
}

.helper-holder {
	cursor: pointer;
	width: 90px;
	height: 90px;
	background: #ffffff;
	box-shadow: 0px 6px 12px 1px rgba(0, 0, 0, 0.12);
	border-radius: 45px 45px 45px 45px;
	position: absolute;
	right: 0;
	top: 1rem;
}
</style>



<script>
export default {
	name: "vHelper",
	props: {},
	data() {
		return {};
	},
	methods: {
		handleClick() {
			this.$emit('scrollToContactForm');

		},
	},
	computed: {

	},
};
</script>
<style scoped></style>