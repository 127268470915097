import axios from 'axios'

// 获取列表
export function newMessage(data) {
  return axios({
    url: `${process.env.VUE_APP_MESSAGE_API}/api/message/new`,
    method: 'post',
    data
  })
}
