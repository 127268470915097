<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="page-content" style="margin-bottom: 0;">
    <h1>
      LEAVE MESSAGE
    </h1>

    <p>
      Contact us for more information on Application Consultation,Sample
      Support,FOB Pricing etc.You will be replied within 8 hours.
    </p>

    <div class="form">
      <el-row :gutter="24">
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="info-input-unit">
              <label class="info-label">
                COMPANY:
              </label>
              <input v-model="form.company" type="text" name="Company" maxlength="80" class="info-input"
                placeholder="Your company name" />
            </div>
          </div>
        </el-col>
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="info-input-unit">
              <label class="info-label">
                NAME:
              </label>
              <input v-model="form.name" type="text" name="Name" maxlength="80" class="info-input"
                placeholder="Your name" />
            </div>
          </div>
        </el-col>
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="info-input-unit">
              <label class="info-label">
                E-Mail:
              </label>
              <input v-model="form.email" type="mail" name="Email" maxlength="80" class="info-input"
                placeholder="Your E-mail address" />
            </div>
          </div>
        </el-col>
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="info-input-unit">
              <label class="info-label">
                TEL:
              </label>
              <input type="text" v-model="form.tel" name="Tel" maxlength="80" class="info-input"
                placeholder="Your telephone number" />
            </div>
          </div>
        </el-col>

        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="info-input-unit">
              <label class="info-label">
                COUNTRY/AREA:
              </label>

              <el-select placeholder="Choose country / area" filterable v-model="form.country" @change="updateCitys">
                <el-option v-for="(cities, country) in cityList" :key="country" :label="country"
                  :value="country"></el-option>
              </el-select>
            </div>
          </div>
        </el-col>
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="info-input-unit">
              <label class="info-label">
                CITY:
              </label>

              <el-select filterable v-model="form.city" placeholder="Choose city">
                <el-option v-for="city in citys" :key="city" :label="city" :value="city"></el-option>
              </el-select>
            </div>
          </div>
        </el-col>

        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="grid-content bg-purple">
            <div class="info-input-unit txt-area">
              <label class="info-label">
                INQUIRY:
              </label>
              <el-input type="textarea" v-model="form.message" placeholder="Please leave your message"></el-input>
            </div>
          </div>
        </el-col>
      </el-row>

      <div class="form-line-button">
        <div class="btn-more-reverse" @click="submit">SUBMIT</div>
      </div>
    </div>
  </div>
</template>
<style scoped>
/* 手机屏幕小于等于768px */
@media only screen and (max-width:768px) {

  .page-content {
    padding: 10px;
    width: auto !important;
    overflow: hidden;
  }

}

.page-content {
  padding-top: 5px;
}

.form-line {
  width: 100%;
  display: flex;
}

.el-form-item {
  width: 50%;
}

.form-line-button {
  width: 100%;
  display: flex;
  margin-top: 40px;
  padding-bottom: 80px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
</style>
<script>
// @ is an alias to /src
import { newMessage } from "@/api/message";
import cityList from "../../assets/countries.min.json";
export default {
  name: "DetailHeadRow",
  props: {
    product: Object,
  },
  components: {},
  data() {
    return {
      cityList: cityList,
      citys: [],
      form: {
        name: "",
        email: "",

        message: "",
        company: "",
        country: "",
        city: "",
      },
    };
  },
  watch: {},
  methods: {
    updateCitys() {
      this.citys = this.cityList[this.form.country] || [];
      this.form.city = "";
    },
    submit() {
      newMessage(this.form).then((response) => {
        if (response.message == "success") {
          this.$message({
            message: "Message sent successfully.",
            type: "success",
          });

          this.form = {};
        } else {
          this.$message({
            message: "Message sending failed.",
            type: "error",
          });
        }
      });
    },
  },
  computed: {},
  mounted() { },
  created() { },
};
</script>
