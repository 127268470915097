<template>
	<div class="footer">
		<div class="f-logo-holder">
			<!-- <img src="../assets/logo.png" class="header-logo" /> -->
			<div class="f-icp-holder" style="display: flex">
				<el-col :span="24" :xs="24" :sm="0" :md="0" :lg="0" :xl="0">
					{{ copyright }}
					<br />
					<a class="footer_a" target="_blank">Tel：{{ tel }}</a>
					<br />
					<a class="footer_a" target="_blank">E-Mail：{{ mail }}</a>
				</el-col>

				<el-col :span="24" :xs="0" :sm="24" :md="24" :lg="24" :xl="24">
					{{ copyright }}
					<br />
					<div>
						<a class="footer_a" target="_blank">
							<i class="el-icon-phone" style="color: #2a8fca"></i>
							Tel：{{ tel }}
						</a>

						<a class="footer_a margin80" target="_blank">
							<i class="el-icon-s-promotion" style="color: #2a8fca"></i>
							E-Mail：{{ mail }}
						</a>
					</div>
				</el-col>
			</div>
		</div>
	</div>
</template>

<style scoped>
	.margin80 {
		margin-left: 80px;
	}
</style>

<script>
	import { getList } from "@/api/footer.js";

	import { uuid } from "vue-uuid";
	export default {
		name: "vFooter",
		props: {},
		data() {
			return {
				copyright: "",
				tel: "",
				mail: "",
			};
		},
		methods: {
			toPage(index) {
				var obj = {
					index: index,
					uuid: uuid.v4(),
				};
				this.$store.commit("setIndex", obj);
			},
			toPages(index) {
				var obj = {
					index: index,
					uuid: uuid.v4(),
				};
				this.$store.commit("setIndexs", obj);
			},
			async fetchContent() {
				const res = await getList();

				return res;
			},
		},
		async mounted() {
			let res = await this.fetchContent();
			res.data.forEach((d) => {
				if (d.pagePath == "footer_right") this.copyright = d.content;

				if (d.pagePath == "footer_mail") this.mail = d.content;

				if (d.pagePath == "footer_tel") this.tel = d.content;
			});
		},
		computed: {},
	};
</script>
<style scoped>
	.f-icp-holder {
		height: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: center;
		align-items: center;
		font-weight: 300;
	}

	.header-logo {
		max-height: 60px;
	}

	.footer {
		width: 100%;
		height: 140px;
		background: black;
		font-size: 20px;
	}

	.f-menu {
		height: 113px;
		border-top: 1px solid rgba(151, 151, 151, 0.5);
		border-bottom: 1px solid rgba(151, 151, 151, 0.5);
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
	}

	.f-icp-holder {
		color: white;
	}

	.f-logo-holder {
		height: 100%;
		/* margin-top: 46px; */
	}

	.footer-menu {
		margin: 40px;
		cursor: pointer;
		color: white;
		text-decoration: none;
	}

	.footer_a {
		text-decoration: none;
		color: white;
	}

	@media screen and (max-width: 768px) {
		.f-menu {
			height: 50px;
		}

		.footer-menus {
			cursor: pointer;
			color: white;
			text-decoration: none;
			font-size: 12px;
			margin: 20px;
			display: inline-block;
		}

		.footer {
			height: auto;
		}

		.f-logo-holder {
			height: auto;
			padding-bottom: 10px;
		}

		.f-logo-holder {
			margin-top: 15px;
		}

		.header-logo {
			height: 30px;
			margin-top: 25px;
			margin-bottom: 25px;
		}
	}
</style>