import request from '@/utils/request'
import axios from 'axios'

let baseUrl = `${window.location.protocol}//${window.location.host}/contents/`;


// 获取列表
export function getList(params) {

  if (process.env.VUE_APP_CONFIG_WAY == 'JSON') {
    let url = baseUrl + 'homepage.json?t=' + new Date().getTime()
    return axios.get(url)
      .then(response => {
        return {
          data: {
            items: response.data
          }
        }
      })
      .catch(error => {
        console.error('Error fetching static data:', error)
        throw error
      })

  } else {
  return request({
    url: '/homepage/list',
    method: 'get',
    params
  })
}
}



export function getNews(data) {
  if (process.env.VUE_APP_CONFIG_WAY == 'JSON') {
    let url = baseUrl + 'news.json?t=' + new Date().getTime()
    return axios.get(url)
      .then(response => {
        return {
          data: {
            items: response.data
          }
        }
      })
      .catch(error => {
        console.error('Error fetching static data:', error)
        throw error
      })

  } else {
    return request({
      url: '/news/get',
      method: 'post',
      data
    })
  }
}
