<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
	<div class="item-card" @click="gotoDeatil(items)">
		<div>
			<div class="img-holder">
				<img :src="items.thumb" class="card-img" />
			</div>
			<div class="card-content">
				<h4 class="card-title">{{ items.name }}</h4>
				<div class="card-label" v-if="items.eNo">
					<span class="card-label-title" >E No.:</span>
					<span class="card-label-value">{{ items.eNo }}</span>
				</div>

				<div class="card-label" v-if=" items.cas">
					<span class="card-label-title" >CAS No.:</span>
					<span class="card-label-value">{{ items.cas }}</span>
				</div>
				<div class="card-label" v-if='items["minOrder"]'>
					<span class="card-label-title" >Min.Order:</span>
					<span class="card-label-value">{{ items["minOrder"] }}</span>
				</div>
				<!-- <div class="card-label">
                    <span class="card-label-title">HSCode:</span>
                    <span class="card-label-value">{{ item.hsCode }}</span>
                  </div> -->
			</div>
		</div>
	</div>
</template>
<style scoped>
.item-card {
	width: 380px;
	height: 480px;
	padding: 0;
	margin: 0px 20px 60px 20px;
	box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.12);
	cursor: pointer;
}

.img-holder {
	width: 100%;
	height: 285px;
}

.card-img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.card-label-title {
	display: block;
	text-align: left;
	width: 120px;

	padding-right: 10px;

	font-size: 18px;
	font-family: Arial;
	font-weight: 400;

	color: #9b9b9b;
	opacity: 1;
}

.card-label {
	display: flex;
	margin-bottom: 5px;
}

.card-label-value {
	display: block;
	flex-grow: 1;
	color: #4a4a4a;
}

.card-label {
	height: 25px;
	text-align: left;
	font-size: 18px;
	font-family: Arial;
	font-weight: 400;
	color: #9b9b9b;
	opacity: 1;
}

.card-title {
	font-size: 24px;
	text-align: left;
	font-family: Arial;
	height: 60px;
	font-weight: bold;
	line-height: 30px;
	color: #4a4a4a;
	margin: 15px 0 15px 0;
	opacity: 1;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
}

.card-content {
	margin: 26px;
}
</style>
<script>
// @ is an alias to /src

export default {
	name: "productItem",
	props: {
		items: Object,
	},
	components: {},
	data() {
		return {};
	},
	watch: {},
	methods: {
		gotoDeatil(item) {
			this.$router.push({ name: "ProductDetail", params: { id: item.id } });
		},
	},

	computed: {},
	mounted() { },
	created() { },
};
</script>
