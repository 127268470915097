<template>
	<div class="header" :class="{'home-header':isHome}">
		<img v-if="isHome" @click="handleSelect('Home', ['Home'])" src="../../assets/logo_home.png" class="header-logo" />
		<img v-if="!isHome" @click="handleSelect('Home', ['Home'])" src="../../assets/logo.png" class="header-logo" />

		<div class="menu grid-content bg-purple menu-pc" style="float: right">
			<el-menu style="height: 100%" :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
				@select="handleSelect" background-color="transparent" popper-class="submenu"

				:text-color="isHome?'#ffffff':'#000000'"
				:active-text-color="isHome?'#ffffff':'#000000'">

				<div v-for="item in menuItems" :key="item.index">
					<el-menu-item v-if="!item.submenu" :index="item.index">
						<div :class="getPathActive(item.index)">{{ item.name }}</div>
					</el-menu-item>
					<el-submenu v-else :index="item.index">
						<template slot="title">
							<div :class="getPathActive(item.index)">{{ item.name }}</div>
						</template>
						<el-menu-item v-for="subItem in item.submenu" :class="{'home-sub-header':isHome,'inner-sub-header':!isHome,}" :key="subItem.index" :index="subItem.index">
							{{ subItem.name }}
						</el-menu-item>
					</el-submenu>
				</div>
				<div class="search-icon-holder" @click="doSearch">
					<div class="gradient-background">
						<unicon name="search" class="menu-icon" fill="#008BD0"></unicon>
					</div>
				</div>

			</el-menu>
		</div>

		<div class="menu-pad menu">
			<div class="search-icon-holder" @click="openMenu()">
				<div class="gradient-background">
					<unicon name="bars" class="menu-icon" fill="#008BD0"></unicon>
				</div>
			</div>

			<div class="search-icon-holder" @click="doSearch">
				<div class="gradient-background">
					<unicon name="search" class="menu-icon" fill="#008BD0"></unicon>
				</div>
			</div>
		</div>

		<div class="menu-mobile menu">
			<div class="search-icon-holder" @click="openMenu()">
				<div class="gradient-background">
					<unicon name="bars" class="menu-icon" fill="#008BD0"></unicon>
				</div>
			</div>
			<img v-if="isHome" @click="handleSelect('Home', ['Home'])" src="../../assets/logo_home.png" class="header-logo-mobile" />
			<img v-if="!isHome" @click="handleSelect('Home', ['Home'])" src="../../assets/logo.png" class="header-logo-mobile" />
 

			<div class="search-icon-holder" @click="doSearch">
				<div class="gradient-background">
					<unicon name="search" class="menu-icon" fill="#008BD0"></unicon>
				</div>
			</div>
		</div>

		<el-drawer :append-to-body="true" :visible.sync="menuOpen" size="100%" direction="ttb" :before-close="handleClose">
			<div class="mobile-menu-holder">
				<div class="menu-unit">
					<div v-for="menuItem in menuItems" :key="menuItem.index">
						<div class="menu-parent-unit" @click="menuItem.submenu ?
							(openedMenu == menuItem.index ? (openedMenu = '') : (openedMenu = menuItem.index))
							: handleSelect(menuItem.index, [menuItem.index])">
							{{ menuItem.name }}
						</div>
						<div v-if="menuItem.submenu" class="menu-children" v-show="openedMenu == menuItem.index"
							:key="menuItem.index">
							<div class="menu-children-unit" v-for="submenuItem in menuItem.submenu" :key="submenuItem.index"
								@click="handleSelect(submenuItem.index, [menuItem.index, submenuItem.index])"
								:index="submenuItem.index">
								{{ submenuItem.name }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-drawer>


		<el-dialog :visible="searchVisiable" width="100%" :close-on-click-modal="true" :before-close="closeSearch"
			:modal="true" class="custom-dialog" :append-to-body="true" :lock-scroll="true" :show-close="false">
			<div class="search-holder " slot>
				<div class="custom-input">
					<input class="input-field" type="text" placeholder="Search keywords..." v-model="keywords" />
					<unicon @click="gotoSearch" name="search" class="  icon unicon" fill="#008BD0"></unicon>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<style>
.el-submenu__title i{
	color:currentColor !important
}
.submenu{
	color:yellow
}
.home-header{
	background:transparent !important
}
.el-dialog {
	background: transparent !important;
	border-radius: 0.125rem;
	-webkit-box-shadow: 0 0 !important;
	box-shadow: 0 0 !important
}

.custom-dialog .el-dialog__header,
.custom-dialog .el-dialog__footer,
.custom-dialog .el-dialog__close {
	display: none;
	/* 隐藏标题栏、底部和关闭按钮 */
}

.custom-dialog .el-dialog__body {
	padding: 0;
	/* 清除默认的内边距 */
	background: transparent;
	/* 设置背景为透明，可根据需要自定义背景样式 */
}

.custom-dialog .content {
	/* 设置slot中内容的样式 */
	/* 这里是一个示例，你可以根据自己的需求进行修改和扩展 */
	padding: 20px;
	background-color: transparent !important;
	border: 0px solid #ccc;
}

.custom-input {
	position: relative;
	width: 1200px;
	margin: auto;
	overflow: hidden;
	border-radius: 50px;
	height: 3em;
	box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.2);
}

.input-field {
	width: 100%;
	height: 100%;
	/* padding-right: 24px; */
	padding-left: 24px;
	font-size: 20px;
	color: #333;

	border: 0;

	/* 留出空间给icon */
}

.icon {
	width: 80%;
	margin: auto;
}

.unicon {
	display: flex;
}

.icon {
	display: flex;
	align-items: center;
	position: absolute;
	top: 50%;
	right: 24px;
	transform: translateY(-70%);
	width: 2em;
	height: 0;

	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}
</style>


<script>

import { getList } from "@/api/menu";



export default {
	name: "vHeader",
	props: {
		isHome: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			menuItems: [

			],
			searchVisiable: false,
			drawer: false,
			activeIndex: "",
			openedMenu: "",
			menuOpen: false,
			indexTip: "",
			tipSitch: false,

			keywords: '',
		};
	},
	methods: {
		async fetchDate() {
			const response = await getList();
			console.log(response)
			this.menuItems = response.data.items;
		},
		closeSearch() {
			this.searchVisiable = false;
		},
		gotoSearch() {
			this.searchVisiable = false;
			this.$router.push({ name: "Search", params: { keyword: this.keywords } });
		},
		doSearch() {

			// this.$emit('showSearch');
			console.log('search')
			this.searchVisiable = true;
		},
		handleClose() {
			console.log("close");
			this.menuOpen = false;
			this.openedMenu = "";
		},
		openMenu() {
			this.menuOpen = true;

			console.log("open menu");
		},
		getPathActive(key) {
			let path = this.$route.name;
 
			if (path == "Home") {
				if (key == "Home") {
					return "active_home";
				}
				return "";
			} else {
				if (key == "About") {
					if (
						["About", "Certification", "Partner", "Service"].some((keyword) =>
							path.includes(keyword)
						)
					) {
						return "active_inner";
					} else {
						return "";
					}
				} else {
					if (path.includes(key)) {
						return "active_inner";
					} else {
						return "";
					}
				}
			}
		},
		handleSelect(key, keyPath) {
			this.menuOpen = false;
			console.log(keyPath);
			console.log(key);
			switch (keyPath[0]) {
				case "ContactUs":
					if (this.$route.name !== key) {
						this.$router.push({ name: key });
					}
					break;

				case "News":
					if (this.$route.name !== key) {
						this.$router.push({ name: key });
					}
					break;
				case "Product":
				case "Application":
					if (
						this.$route.name !== keyPath[0] ||
						this.$route.params.cate !== keyPath[1]
					) {
						this.$router.push({
							name: keyPath[0],
							params: { cate: keyPath[1] },
						});
					}
					break;
				case "About":
					if (this.$route.name !== key) {
						this.$router.push({ name: key });
					}
					break;
				case "Home":
					if (this.$route.name !== key) {
						this.$router.push({ name: key });
					}
					break;
			}
		},

		showMenu() {
			console.log("show menu");
			this.menuOpen = true;
			this.tipSitch = true;
		},
	},
	watch: {},
	computed: {
		showTipPages: function () {
			return this.$store.state.hideTip;
		},
	},
	mounted() { 
		this.fetchDate();
	},
};
</script>
<style>
.el-dropdown-menu__item,
.el-menu-item {
	padding: 0 !important;
	width: 210px
}

.gradient-background {
	background: linear-gradient(to right, #38b1bf, #008bd0);
	color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
}

.search-icon-holder {
	margin-left: 30px;
	width: 50px;
	height: 50px;
	background: white;
	border-radius: 50px;
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
	display: none;
}

.active_inner {
	font-weight: bold;
	color: #2A8FCA !important;
}
.active_home{
	font-weight: bold;
}

.el-submenu {
	width: 210px !important;
}

.is-active {
	border: 0 !important;
}

.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
	min-width: 210px !important;
	width: 210px !important;
}

.el-menu--popup {
	width: 210px !important;
}

.el-menu--popup .el-menu-item {
	background: rgba(244, 244, 244, 0.8);
}

.home-sub-header  {
	background: rgba(2, 51, 65, 0.38) !important;
}
.inner-sub-header  {
	background: rgba(244, 244, 244, 0.8) !important;
}



.el-menu--popup {
	padding: 0 !important;
}

.el-submenu__title,
.el-menu-item {
	font-size: 18px !important;
	color:black
}

.is-opened .el-submenu__title {
	background: rgba(2, 51, 65, 0.36) !important;
}

.el-menu--popup-bottom-start {
	margin-top: 0px !important;
	text-align: center !important;
}

.el-menu--popup-bottom-start li {
	height: 58px !important;
	line-height: 58px !important;
	font-size: 16px !important;
	font-family: Arial;
	font-weight: 400;
	/* line-height: 0px; */
	/* color: #ffffff !important; */
	opacity: 1;
}



</style>

<style scoped>
@media only screen and (min-width: 768px) {
	.menu-icon {
		width: 100% !important;
		margin: auto;
	}
}

.menu-icon {
	width: 80%;
	margin: auto;
}

.unicon {
	display: flex;
}

.menu-pad {
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.menu-mobile .search-icon-holder {
	margin: 0 !important;
}

.menu-mobile {
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: 60px !important;
	margin-right: 60px !important;
	width: 100%;
}

.menu {
	height: 150px;
	line-height: 150px;
	display: flex;
	/* color: #FFFFFF; */
	font-size: 18px !important;
	margin-right: 120px;
}

.menu ul {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: nowrap;
	flex-direction: row;
}

.header {
	width: 100%;
	height: 150px;
	position: absolute;
	top: 0;
	left: 0;
	background:white;
	color:black !important;
	/* background: yellow; */
	/* background: linear-gradient(90deg, #38b1bf 0%, #008bd0 100%) !important; */
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
}
.el-menu-item{
	/* color:black !important; */
}

.header-logo {
	width: 274px;
	position: absolute;
	left: 0;
	padding: 42px 120px;
	top: 0;
	z-index: 9;
	cursor: pointer;
}

.menu-btn {
	margin: 40px;
	cursor: pointer;
	color: white;
	text-decoration: none;
}

.menu-btn:last-of-type {
	margin-right: 141px;
}

@import "../../common/flexible/header.css";
</style>
