<template>
  <div>
    <vHeader @showSearch="showSearch" class="header"></vHeader>

    <!-- <img src="../../assets/product_banner.jpg" class="page-banner" /> -->

    <!-- <img :src="currentParent.banner" class="page-banner" /> -->
    <vBanner :showSearch='searchVisible' :imgPath="currentParent.banner" class="page-banner"></vBanner>



  </div>
</template>
<style>
@import "../../common/common.css";
</style>

<script>
import vBanner from "../Common/banner.vue";

// import bannerList from "../../utils/contents";
import vHeader from "../Common/header";
export default {
  name: "productPage",
  props: {

    currentParent: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {
      searchVisible:false,
      defaultImg: '',
      // banners:bannerList.banners
    };
  },
  methods: {
    showSearch() {
      this.searchVisible = true;
    },
    // topre() {
    //   this.swiper.slidePrev();
    // },
    // tonext() {
    //   this.swiper.slideNext();
    // }
  },
  components: { vHeader, vBanner },
  computed: {
    // swiper() {
    //   return this.$refs.mySwiper.$swiper;
    // }
  },
  mounted() { },
};
</script>
