<template>
  <div>
    <vHeader @showSearch="showSearch" class="header"></vHeader>

    <!-- <img src="../../assets/about_banner.jpg" class="page-banner"> -->
    <vBanner
      :showSearch="searchVisible"
      :pagePath="pagePath"
      class="page-banner"
    ></vBanner>

    <div class="page-content">
      <vContent :pagePath="pagePath"></vContent>
    </div>

    <div class="page-content-full">
      <!-- 整个屏幕宽度的部分，目前只在service和about页面中存在 -->
      <vContent :pagePath="'about_full'"></vContent>
    </div>

    <!-- <div class="page-content-full">
      <div class="bg">
        <div class="page-content about-cart-list">
          <div class="about-card">
            <img class="about-card-img" src="../../assets/icon-tel.png" />
            <div class="about-card-txt">High-quality products</div>
          </div>
        </div>
      </div>
    </div> -->


  </div>
</template>
<style scoped>
.page-content {
  margin-bottom: 140px;
}
</style>

<script>
import vBanner from "../Common/banner.vue";
import vContent from "../Common/content.vue";

import vHeader from "../Common/header";

export default {
  name: "aboutPage",
  props: {},
  data() {
    return {
      pagePath: "about",
      searchVisible: false,
      swiperOptions: {
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // autoplay: true

        // Some Swiper option/callback...
      },
      activeIndex: 1,
    };
  },
  methods: {
    showSearch() {
      this.searchVisible = true;
    },
    // topre() {
    //   this.swiper.slidePrev();
    // },
    // tonext() {
    //   this.swiper.slideNext();
    // }
  },
  components: { vHeader, vBanner, vContent },
  computed: {
    // swiper() {
    //   return this.$refs.mySwiper.$swiper;
    // }
  },
  mounted() {},
};
</script>
