<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
	<div class="item-card2" @click="gotoDeatil(item)">
		<img :src="item.thumb" class="card-main-pic" />

		<div class="title-holder">
			<div class="title-inner-holder">
				<h4 class="card-title2">{{ item.name }}</h4>
				<div class="card-tag" :class="['color-block', item.grade]">
					{{ item.cas }}
				</div>
			</div>
		</div>

		<img :src="getPack(item)" class="card-sub-pic" />
	</div>
</template>
<style scoped>
/* Small devices (tablets, 768px and up) */
@media (max-width: 768px) {
	/* Custom styles for sm screens */

	.item-card2 {
		width: 278px !important;
		height: 440px !important;
	}

	.card-sub-pic {
		width: 125px !important;
		height: 140px !important;
		left: 0 !important;
		top: 148px !important;
	}

	.card-main-pic {
		width: 278px !important;
		height: 210px !important;
	}

	.title-holder {
		position: absolute;
		left: 0;
		top: 80px;
	}

	.title-inner-holder {
		left: 0px !important;
		width: auto !important;
		padding: 10px;
	}

	.card-title2 {
		height: 70px;
	}
	.card-tag {
		width:15rem !important;
	}
}

.card-title2 {
	padding: 3px;
	margin-top: 10px;
	margin-bottom: 5px;
	text-align: left;
	font-size:20px;

}

.item-card2 {
	cursor: pointer;
	width: 400px;
	height: 440px;
	position: relative;
	/* padding: 10px; */
	margin: 0px 10px 60px 10px;
	box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.12);
}

.card-label {
	display: flex;
	margin-bottom: 5px;
}

.card-label-value {
	display: block;
	flex-grow: 1;
	color: #4a4a4a;
}

.card-label {
	height: 25px;
	text-align: left;
	font-size: 18px;
	font-family: Arial;
	font-weight: 400;
	color: #9b9b9b;
	opacity: 1;
}

.card-label-title {
	display: block;
	text-align: left;
	width: 100px;

	padding-right: 10px;

	font-size: 18px;
	font-family: Arial;
	font-weight: 400;

	color: #9b9b9b;
	opacity: 1;
}

.feed {
	background-color: #D37520 !important;
	color: white !important;
}

.tech {
	background-color: #2B68B0 !important;
	color: white !important;
}

.food {
	background-color: #419826 !important;
	color: white !important;
}

.pharma {
	background-color: #C31D43 !important;
	color: white !important;
}

.title-holder {
	width: 100%;
	/* background-color: yellow; */
	position: relative;
	height: 140px;
}

.card-main-pic {
	width: 100%;
	height: 300px;
	object-fit: cover;
}

.card-tag {
	width: 176px;
	height: 30px;
	background: #b4babc24;
	border-radius: 8px 8px 8px 8px;
	opacity: 1;
	line-height: 30px;
}

.card-sub-pic {
	width: 180px;
	height: 200px;
	position: absolute;
	left: 0px;
	top: 210px;
	object-fit: cover;
}

.title-inner-holder {
	width: 220px;
	position: absolute;
	left: 180px;
	display: flex;
	height: 100px;
	flex-direction: column;
	flex-wrap: nowrap;

	align-items: flex-start;
	justify-content: space-between

;
}
</style>
<script>
// @ is an alias to /src

export default {
	name: "applicationItem",
	props: {
		item: Object,
	},
	components: {},
	data() {
		return {

			gradePack: {
				feed:require('../../../assets/pack_feed.png'),
				pharma:require('../../../assets/pack_pharma.png'),
				tech:require('../../../assets/pack_tech.png'),
				food:require('../../../assets/pack_food.png')

			}



		};
	},
	watch: {},
	methods: {
		gotoDeatil(item) {
			console.log(item);

			//check available
			if (item.detailPage) {
				const id = item.detailPage.pop();
				this.$router.push({ name: "ProductDetail", params: { id: id } });
			}
		},
		getPack(row) {
			if (row.packpic) {
				return row.packpic
			} else {
				return this.gradePack[row.grade]
			}

		},
	},

	computed: {},
	mounted() {
		console.log(
			"\x1b[42m%s\x1b[0m",
			"productItem.vue line:126 this.item",
			this.item
		);
	},
	created() { },
};
</script>
