<template>
	<div>
		<vHeader @showSearch="showSearch" class="header"></vHeader>

		<!-- <img src="../../assets/contact_banner.jpg" class="page-banner" /> -->
		<vBanner :showSearch='searchVisible' :pagePath="pagePath" class="page-banner"></vBanner>

		<div class="page-content" style="    display: flex;
    flex-direction: column;">
			<vContent :pagePath="pagePath"></vContent>
		</div>
		<div class="page-form gray-bg">
			<ContactForm></ContactForm>
		</div>
		<!-- <div class="dvider"></div> -->
		<div class="page-content fix-top">
			<vContent :pagePath="pagePath2"></vContent>
		</div>
	</div>
</template>
<style scoped>
p span {
	color: #333333;
	font-weight: bold;
}

.fix-top {
	margin-top: 100px;
}

a {
	/* margin-right: 1rem; */
}

.page-content {
	margin-bottom: 100px;
}

.form-line-text {
	width: 100%;
}

.page-form .page-content {
	width: 1680px;
}

.page-content p {
	text-align: left;
	font-size: 24px;
	font-family: Arial;
	font-weight: 400;
	line-height: 40px;
	color: #4a4a4a;
	opacity: 1;
	overflow: hidden;
	word-break: break-all;
}



a {
	text-decoration: none;
	color: inherit;
}

.fake-table {
	width: 100%;
}

.table-tr {
	vertical-align: text-bottom;
	text-align: left;
}

.table-title {
	width: 200px;

	font-size: 24px;
	font-family: Arial;
	font-weight: bold;
	line-height: 40px;
	color: #333333;
	opacity: 1;
}

.table-content {
	font-size: 24px;
	font-family: Arial;
	font-weight: 400;
	line-height: 40px;
	color: rgb(51 51 51 / 60%);
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
	/* opacity: 0.6; */
}

@import "../../common/common.css";
</style>

<style></style>

<script>
import vBanner from "../Common/banner.vue";
import vContent from "../Common/content.vue";
import vHeader from "../Common/header";

import ContactForm from "../Common/contactForm.vue";

export default {
	name: "contactPage",
	props: {},
	data() {
		return {
			searchVisible: false,
			pagePath: "contact",
			pagePath2: "contact2",
			swiperOptions: {
				loop: true,
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				// autoplay: true

				// Some Swiper option/callback...
			},
			activeIndex: 1,
		};
	},
	methods: {
		showSearch() {
			this.searchVisible = true;
		},
		// topre() {
		//   this.swiper.slidePrev();
		// },
		// tonext() {
		//   this.swiper.slideNext();
		// }
	},
	components: { vHeader, ContactForm, vBanner, vContent },
	computed: {

		// swiper() {
		//   return this.$refs.mySwiper.$swiper;
		// }
	},
	watch: {
		// "form.country": function (newCountry) {
		// 	this.citys = this.cityList[newCountry] || [];
		// 	this.form.city = "";
		// }
	},
	mounted() { },
};
</script>
