<template>
	<el-container>
		<el-main class="section-page home-news">
			<div class="page ">
				<div class="bg-big-txt-center">
					NEWS

				</div>

				<div class="section-inner2">
					<div class="news-special-h1">
						<h1 class="">NEWS</h1>
					</div>
					<div class="home-news-swiper ">


						<swiper ref="mySwiper" class="news-swiper" :options="swiperOptions">
							<swiper-slide class="news-holder" :data-index="n.id" v-for="n in newsList" v-bind:key="n.id" @click="goto(n)">
								<div class="swiper-holder">
									<div class="section-inner">
										<el-row :gutter="10" class="section-inner-row">
											<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
												<div class="section-parts">
													<img class="news-home-image" :src="n.thumb" />
												</div>
											</el-col>
											<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
												<div class="section-parts news-part-text">
													<div class="home-news-title">
														{{ n.title }}
													</div>

													<div class="home-news-date">
														<p>{{ n.date }}</p>
													</div>
													<div class="home-news-button">
														<div class="btn-more-reverse">
															LEARN MORE >
														</div>
													</div>
												</div>
											</el-col>
										</el-row>
									</div>
								</div>
							</swiper-slide>
						</swiper>

						<div class="swiper-button-prev"></div>
						<!--左箭头。如果放置在swiper外面，需要自定义样式。-->
						<div class="swiper-button-next"></div>
						<!--右箭头。如果放置在swiper外面，需要自定义样式。-->

					</div>

				</div>
			</div>
		</el-main>
	</el-container>
</template>
<style scoped>
.home-news-button {

	position: relative;
	z-index: 5;
}

.section-inner-row {
	width: 100%;
}

@media only screen and (max-width:768px) {
	.home-news-swiper {
		height: 600px !important;
	}

	.news-part-text {
		margin-top: 5px !important;
		align-items: center !important
	}

}

.home-news-date {
	width: 100%;
	display: flex;
	text-align: left !important;
}

.home-news-swiper {
	height: 450px;
	margin-top: 60px;
}

.section-inner2 {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
}

.page {
	position: relative;
	overflow: hidden;
	display: flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
}

.section-pic {
	width: 800px;
}

.btn-more-reverse {
	margin-top: 150px;
}

@import "../../common/flexible/home_news.css";
</style>
<script>
let vm = null;
import { getList } from "@/api/news";
export default {
	name: "newsHolder",
	props: {},
	data() {
		return {
			swiperOptions: {
				loop: true,
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				// autoplay: true

				// Some Swiper option/callback...
				on: {
					click: function () {
						// const realIndex = this.clickedIndex;
						// vm.handleClickSlide(realIndex);

						// console.log(this.clickedSlide.dataset)
						vm.goto(this.clickedSlide.dataset.index)
					},
				},
				preventLinksPropagation: false   // 阻止点击事件冒泡
			},
			newsList: [],
			activeIndex: 1,
		};
	},
	methods: {
		handleClickSlide(realIndex) {
			console.log('\x1b[42m%s\x1b[0m', 'news.vue line:153 realIndex', realIndex);
			this.goto(this.newsList[realIndex])
		},
		goto(index) {
			// console.log('\x1b[42m%s\x1b[0m', 'news.vue line:142 n', n);
			this.$router.push({ name: "NewsPage", params: { id: index } });
		},
		async init() {

			const response = await getList();
			this.newsList = response.data.items;
		},
	},
	components: {},
	computed: {
		// swiper() {
		// 	return this.$refs.mySwiper.$swiper;
		// },
	},
	mounted() {
		this.init();
	}, created() {
		vm = this;
	},
};
</script>
