<template>
  <div>

    <vHeader @showSearch="showSearch" class="header"></vHeader>
    <!-- <img src="../../assets/contact_banner.jpg" class="page-banner" /> -->

    <vBanner :showSearch='searchVisible' :pagePath="pagePath" class="page-banner"></vBanner>

    <div class="page-content">
      <h1>NEWS</h1>
      <div class="tab-holder">
        <div class="tab-button" :class="{ 'tab-button-active': currentState === 'all' }" @click="chooseTab('all')">
          All
        </div>
        <div v-for="tab in btns" :key="tab.id">

          <div v-if="tab.enabled == true" class="tab-button"
            :class="{ 'tab-button-active': currentState === tab.pagePath }" @click="chooseTab(tab.pagePath)">
            {{ tab.label }}
          </div>

        </div>


        <!-- <div class="tab-button" :class="{ 'tab-button-active': currentState === 'company' }"
          @click="chooseTab('company')">
          Company News
        </div>
        <div class="tab-button" :class="{ 'tab-button-active': currentState === 'industry' }"
          @click="chooseTab('industry')">
          Industry Information
        </div>
        <div class="tab-button" :class="{ 'tab-button-active': currentState === 'latest' }" @click="chooseTab('latest')">
          Latest Event
        </div> -->
      </div>

      <div class="news-list">

        <div v-for="(news, index) in currentPageNews" :key="index" class="news-unit">

          <img class="news-image" :src="news.thumb">

          <div class="news-content">
            <p class="date">{{ news.date }}</p>
            <h3>{{ news.title }}</h3>
            <p class="abstract">{{ news.desc }}</p>
            <div @click="gotoNews(news)" class="btn-more-txt">LEARN MORE ></div>
          </div>


        </div>
      </div>

      <br> <br> <br>
      <div class="pagination-holder">
        <el-pagination layout="prev, pager, next" v-model="currentPage" :page-size="pageSize"
          @size-change="handlePageSizeChange" @current-change="handlePageChange" :total="filteredItems.length" />
      </div>
    </div>
  </div>
</template>
<style scoped>
.news-content .abstract {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-content {
  margin-left: 80px;
  max-width: 1000px;
}

@media (min-width: 768px) and (max-width: 1024px) {

  /* Custom styles for sm screens */
  .news-content {

    max-width: 600px !important;
  }
}


@media (max-width: 767.98px) {
  .page-content h1 {
    margin-top: 0 !important;
    padding-top: 0 !important
  }

  .tab-holder {
    flex-wrap: wrap !important;
  }

  .news-unit {
    height: 900px !important;
    flex-direction: column !important;
    margin-bottom: 80px;
  }

  .news-image {
    width: 100% !important;
    height: 440px !important
  }

  .news-content {
    margin-left: 0 !important;
    width: 100% !important
  }

  .tab-button {
    width: 100% !important;
    margin-top: 5px;
  }

}


/* Small devices (tablets, 768px and up) */


.news-content {
  text-align: left;
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.news-content h3 {
  font-size: 30px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-unit .date {
  margin: 0;
}

.news-unit {
  display: flex;
  height: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 100px;
}

.news-image {
  width: 600px;
  height: 400px;
  object-fit: cover;
}



.news-list {
  margin-top: 50px;
}

.page-content {
  width: initial;
  margin: 120px;
}

.tab-holder {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-between;
  justify-content: flex-start;
}

.tab-button {
  cursor: pointer;
  background: #f5f5f5;
  border-radius: 8px 8px 8px 8px;
  padding: 27px 70px;
  font-size: 24px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #4a4a4a;
  margin-right: 20px;
  text-transform: uppercase;
}

.tab-button-active {
  background: #ebf5fb;
  border-radius: 8px 8px 8px 8px;
  font-family: Arial-Bold, Arial;
  font-weight: bold;
  color: #2a8fca;
}

@import "../../common/common.css";
</style>
<script>
import { getCategory, getList } from "@/api/news";
import vBanner from "../Common/banner.vue";
import vHeader from "../Common/header";
export default {
  data() {
    return {
      btns: {},
      tabs: {
        company: {
          pagePath: 'company',
          label: 'Company News'
        }
        ,
        industry: {
          pagePath: 'industry',
          label: 'Industry Information'
        }
        ,
        latest: {
          pagePath: 'latest',
          label: 'Latest Event'
        }
      }, // 添加tabs变量,
      searchVisible: false,
      pagePath: "news",
      currentState: "all",
      list: [], // 你的新闻数据应该放在这里
      currentPage: 1,
      pageSize: 10,

    };
  },
  computed: {
    filteredItems() {
      if (this.currentState === 'all') {
        return this.list;
      } else if (this.currentState === 'latest') {
        return [...this.list].sort((a, b) => new Date(b.date) - new Date(a.date));
      } else {
        return this.list.filter((item) => item.type.includes(this.currentState));
      }
    },
    currentPageNews() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.filteredItems.slice(start, end);
    },
  },
  components: { vHeader, vBanner },
  methods: {

    showSearch() {
      this.searchVisible = true;
    },
    gotoNews(news) {
      this.$router.push({ name: 'NewsPage', params: { id: news.id } })
    },
    async fetchData() {
      this.listLoading = true;
      const response = await getList();
      this.list = response.data.items;

      getCategory().then(response => {

        if (response.code === 20000) {

          response.data.items.forEach(d => {
            if (this.tabs[d.pagePath]) {
              this.tabs[d.pagePath].enabled = d.enabled;

            }
          })
          this.btns = this.tabs;
        }
      }).catch(error => {
        console.error(error);
      });
      this.listLoading = false;
    },
    chooseTab(tab) {
      this.currentState = tab;
      this.currentPage = 1;
    },
    handlePageSizeChange(val) {
      this.pageSize = val;
    },
    handlePageChange(val) {
      this.currentPage = val;
    },
  },
  async created() {
    // 在这里获取列表内容
    await this.fetchData();

    this.chooseTab('all');
  },
};
</script>

<style scoped>
.news-unit {
  display: flex;
}

.news-content {}
</style>
