<template>
	<div class="home">
		<div class="page-minheight">
			<vProduct :currentParent="currentParent"></vProduct>

			<h2 class="current-path path-pc">· {{ currentCate.label }} ·</h2>

			<div class="product-list">
				<div class="sidebar">
					<vSideList :cateList="sidebarData" :currentCate="currentCate" :currentParent="currentParent">
					</vSideList>
					<vHelper class="helper-magrin path-pc" @scrollToContactForm="gotoContact"></vHelper>
				</div>
				<h2 class="current-path path-mobile">· {{ currentCate.label }} ·</h2>
				<!-- 左侧侧边栏-->
				<div class="content-holder">
					<!-- 右侧内容区域-->
					<vHelperMini class="mini-helper path-mobile" @scrollToContactForm="gotoContact"> </vHelperMini>
					<div class="content">
						<ItemCard :items="item" v-for="item in currentItemList.slice(
							(currentPage - 1) * pageSize,
							currentPage * pageSize
						)" :key="item.id"></ItemCard>
					</div>

					<div class="pagination-holder">
						<el-pagination @current-change="handlePageChange" :current-page="currentPage" :page-size="pageSize"
							layout="prev, pager, next" :total="currentItemList.length">
						</el-pagination>
					</div>
				</div>
			</div>

		</div>
		<vFooter></vFooter>
	</div>
</template>
<style scoped>
.mini-helper {
	z-index: 5;
}

.current-path {
	/* width: 100%; */
	text-align: center;
	font-size: 30px;
	font-family: Arial-Bold, Arial;
	font-weight: bold;
	color: #2a8fca;
	line-height: 80px;
	padding: 30px;
}

.helper-magrin {
	margin-top: 40px;
}

.content-holder {
	position: relative;
	width: 100%;
	padding-bottom: 100px;
}

.pagination-holder {
	position: absolute;
	right: 0;
	bottom: 0;
}

.product-list {
	padding: 120px;
	padding-top: 0px;
	/* background: yellow; */
	display: flex;
	position: relative;
	margin-top: 0;
}

.sidebar {
	width: 400px;
	height: 100%;
}

.content {
	min-height: 100px;
	width: 100%;
	height: 100%;
	margin-left: 30px;
	/* background: green; */
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: flex-start;
	justify-content: flex-start;
	align-items: flex-start;
}

@import "../common/flexible/product_app.css";
@import "../common/flexible/fixheight.css";
</style>

<script>
// @ is an alias to /src

import { getCateList, getList } from "@/api/products";
import vFooter from "../components/Common/footer.vue";
import vHelper from "../components/Common/help.vue";
import vHelperMini from "../components/Common/help_mini.vue";

import ItemCard from "../components/Common/product/productItem.vue";

import vSideList from "../components/Product/category_list.vue";
import vProduct from "../components/Product/product.vue";

export default {
	name: "ProductList",
	components: { vSideList, vProduct, vFooter, vHelper, ItemCard, vHelperMini },
	data() {
		return {
			currentCate: {},
			currentParent: {},
			currentItemList: [],

			cateList: [],
			itemList: [],
			sidebarData: [],

			currentPage: 1,
			pageSize: 12,
		};
	},
	watch: {
		"$route.params.cate"(newCate) {
			this.fetchData();
			this.currentPage = 1; // 切换分类时回到第一页
		},
	},
	methods: {
		gotoContact() {
			console.log('contact')
			this.$router.push({ name: 'ContactUs' });
		},
		async fetchData() {
			this.listLoading = true;

			const { cate } = this.$route.params;

			const response1 = await getList();
			const response2 = await getCateList();

			this.itemList = response1.data.items;
			this.cateList = response2.data.items;

			this.formatData(this.cateList);

			this.currentCate = this.cateList.find(
				(catex) => catex.id === cate || catex.label === cate
			);

			if (this.currentCate.level === "Root") {
				this.currentParent = { ...this.currentCate };
			} else if (this.currentCate.level === "Sub") {
				this.currentParent = this.cateList.find(
					(catex) => catex.id === this.currentCate.parent
				);
			}

			// 过滤出当前目录下的产品
			this.currentItemList = this.itemList.filter(
				(item) => {
					if (item.catgory) {
						return item.catgory.indexOf(this.currentCate.id) > -1
					} else {
						return false
					}
				}
			);

			this.listLoading = false;
		},

		handlePageChange(page) {
			this.currentPage = page;
			this.fetchData();
		},
		gotoDeatil(item) {
			console.log("\x1b[42m%s\x1b[0m", "Products.vue line:279 item", item);
			this.$router.push({ name: "ProductDetail", params: { id: item.id } });
		},
		formatData(rawData) {
			// 分类数据
			const roots = [];
			const subs = {};
			rawData.forEach((item) => {
				if (item.level === "Root") {
					item.children = [];
					roots.push(item);
				} else if (item.level === "Sub") {
					if (!subs[item.parent]) {
						subs[item.parent] = [];
					}
					subs[item.parent].push(item);
				}
			});

			// 将子项添加到父项中
			Object.keys(subs).forEach((parent) => {
				const root = roots.find((item) => item.id === parent);
				if (root) {
					root.children = subs[parent];
					root.children.sort((a, b) => a.order - b.order);
				}
			});

			// 排序根项目
			this.sidebarData = roots.sort((a, b) => a.label.localeCompare(b.order));
			// 生成表格数据
			// this.sidebarData = roots.reduce((pre, cur) => {
			//   return pre.concat({
			//     ...cur
			//   }, cur.children.map(item => {
			//     return {
			//       ...item
			//     }
			//   }))
			// }, [])
		},
	},
	computed: {},
	mounted() {
		this.fetchData();
		// this.loadProducts(this.$route.params.cateid);
	},
	created() { },
};
</script>
