<template>
	<div>
		<vHeader class="header" @showSearch="showSearch"></vHeader>
		<vBanner :showSearch='searchVisible' :pagePath="pagePath" class="page-banner"></vBanner>

		<!-- <img src="../../assets/certification_banner.jpg" class="page-banner"> -->

		<div class="page-content">
			<vContent :pagePath="pagePath"></vContent>

			<!-- 
			<h1>CERTIFICATIONS</h1>
			<p>
				Chempharms’ products can supply major certification bodies such as HALAL, KOSHER, ISO 9001/2008, HACCP, SGS,
				UKAS, etc.
			</p>


			<img src="../../assets/certification_list.png" /> -->
		</div>
	</div>
</template>
<style scoped>
	.page-content img {
		margin-bottom: 100px;
		margin-top: 50px;
	}
</style>>

  
<script>
	import vBanner from "../Common/banner.vue";
import vContent from "../Common/content.vue";
import vHeader from "../Common/header";
	export default {
		name: "aboutPage",
		props: {},
		data() {
			return {
				searchVisible:false,
				pagePath: "certification",
				swiperOptions: {
					loop: true,
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
					// autoplay: true

					// Some Swiper option/callback...
				},
				activeIndex: 1,
			};
		},
	methods: {
		showSearch() {
			this.searchVisible = true;
		},
			// topre() {
			//   this.swiper.slidePrev();
			// },
			// tonext() {
			//   this.swiper.slideNext();
			// }
		},
		components: { vHeader, vBanner, vContent },
		computed: {
			// swiper() {
			//   return this.$refs.mySwiper.$swiper;
			// }
		},
		mounted() {},
	};
</script>