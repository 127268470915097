<template>
  <div>
    <vHeader class="header" @showSearch="showSearch"></vHeader>

    <vBanner
      :showSearch="searchVisible"
      :imgPath="imgPath"
      class="page-banner"
    ></vBanner>

    <div class="page-content">
      <vContent :pagePath="pagePath"></vContent>
    </div>

    <div class="page-content">
      <div
        class="btn-more-reverse"
        style="width: 50%; margin: auto"
        @click="back"
      >
        BACK
      </div>
    </div>
  </div>
</template>
<style scoped>
.page-content img {
  margin-bottom: 100px;
  margin-top: 50px;
}
</style>
>

<script>
import vBanner from "../Common/banner.vue";
import vContent from "../Common/content.vue";
import vHeader from "../Common/header";
import { getPage } from "@/api/extraPages";

export default {
  name: "exPage",
  props: {},
  data() {
    return {
      searchVisible: false,
      pagePath: "",
      imgPath: "",
      currentPage: {},
      swiperOptions: {
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // autoplay: true

        // Some Swiper option/callback...
      },
      activeIndex: 1,
    };
  },
  methods: {
    back() {
      if (window.history.length > 1) {
        window.history.back();
      } else {
        window.location.href = "#/service";
      }
    },
    showSearch() {
      this.searchVisible = true;
    },

    async fetchData() {
      const response = await getPage({ pagePath: this.$route.params.id });
      this.currentPage = response.data.items || {};
      this.imgPath = this.currentPage.banner || "";

      //TODO 找不到页面的时候返回404页面；
    },

    init() {
      this.pagePath = this.$route.params.id;
      this.fetchData();
    },
  },
  components: { vHeader, vBanner, vContent },
  computed: {
    // swiper() {
    //   return this.$refs.mySwiper.$swiper;
    // }
  },
  mounted() {
    this.init();
  },
};
</script>
