<template>
	<div>
		<vHeader @showSearch="showSearch" class="header"></vHeader>

		<!-- <img src="../../assets/about_banner.jpg" class="page-banner"> -->
		<vBanner :showSearch='searchVisible' :pagePath="pagePath" class="page-banner"></vBanner>

		<div class="page-content">
			<vContent :pagePath="pagePath"></vContent>

			<!-- <h1>ABOUT CHEMPHARMS</h1>
			<p>
				Chempharms biotech is a Shanghai-based company that specializes in providing a wide range of products
				including thickeners, acidity regulators, sweeteners, vitamins, flavorings and aromas, and APIs. Our
				products are designed to meet the needs of a variety of industries, from food and beverage, feed to
				pharmaceuticals. Our team of experienced professionals is dedicated to delivering innovative solutions to
				meet the unique needs of each of our customers. At Chempharms biotech, we are passionate about helping our
				clients succeed, and we look forward to partnering with you to achieve your business goals.
			</p>


			<h1>Why CHEMPHARMS</h1>

			<table style="border-collapse: collapse;">
				<tr class="table-tr">
					<td class="table-title">International Certifications:</td>
					<td class="table-content">Fully Certified with Halal, Kosher, Veterinary (Health), ISO22000</td>
				</tr>
				<tr class="table-tr">
					<td class="table-title">Strict Quality Control System:</td>
					<td class="table-content">To ensure only 1-class Raw Material Used.</td>
				</tr>
				<tr class="table-tr">
					<td class="table-title">Fast and Reliable Delivery:</td>
					<td class="table-content">a dedicated logistics team that manages global distribution network to ensure
						that customers receive their orders on time.</td>
				</tr>
				<tr class="table-tr">
					<td class="table-title">Exceptional Customer Service:</td>
					<td class="table-content">Professional and friendly customer service team is always available to answer
						questions, provide technical support.</td>
				</tr>
			</table> -->
		</div>
	</div>
</template>
<style scoped>
.page-content {
	margin-bottom: 140px;
}
</style>



<script>
import vBanner from "../Common/banner.vue";
import vContent from "../Common/content.vue";

import vHeader from "../Common/header";


export default {
	name: "partnerPage",
	props: {},
	data() {
		return {
			pagePath: "partner",
			searchVisible: false,
			activeIndex: 1,
		};
	},
	methods: {
		showSearch() {
			this.searchVisible = true;
		},
		// topre() {
		//   this.swiper.slidePrev();
		// },
		// tonext() {
		//   this.swiper.slideNext();
		// }
	},
	components: { vHeader, vBanner, vContent },
	computed: {
		// swiper() {
		//   return this.$refs.mySwiper.$swiper;
		// }
	},
	mounted() { },
};
</script>