<template>
	<el-container>
		<el-main class="section-page home-client">
			<div class="page">
				<el-row :gutter="10" class="section-inner">
					<el-col
						:xs="24"
						:sm="24"
						:md="12"
						:lg="12"
						:xl="12"
						class="section-parts section-left"
					>
						<!-- <img src="../../assets/client_main.jpg" class="section-pic" /> -->
						<img :src="datas.client_pic" class="section-pic" />
					</el-col>

					<el-col
						class="section-parts section-right"
						:xs="24"
						:sm="24"
						:md="12"
						:lg="12"
						:xl="12"
					>
						<div class="right-holder">
							<h1 v-html="datas.client_title"></h1>
							<h4 v-html="datas.client_text">
							</h4>

							<!-- <div class="btn-more" @click="goto">LEARN MORE ></div> -->
						</div>
					</el-col>
				</el-row>
			</div>
		</el-main>
	</el-container>
</template>
<style scoped>
	.section-inner {
		margin: 0 !important;
	}
	.section-page,
	.el-main {
		margin: 0;
		padding: 0;
	}

	.section-pic {
		width: 880px;
		z-index: 5;
	}

	.btn-more {
		margin-top: 80px;
	}

	.section-left {
		width: 58%;
		position: absolute;
		left: 0;
		z-index: 5;
		display: flex;
		padding: 0 !important;
	}

	.section-right {
		width: 57%;
		position: absolute;
		right: 0;
	}

	.section-parts h4,
	.section-parts h1 {
		color: white !important;
		text-align: right;
	}

	.section-right {
		background-image: url("../../assets/client_bg.jpg");
		background-size: cover;
		height: 700px;
	}

	.right-holder {
		display: flex;
		height: 100%;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: center;
		align-items: flex-end;
		padding-right: 90px;
		padding-left: 220px;
	}

	@import "../../common/flexible/home_client.css";
</style>
<script>
	export default {
		name: "clientHolder",
		props: {
		datas: {
			type: Object,
			required: true,
		},},
		data() {
			return {
				swiperOptions: {
					// pagination: {
					// 	el: ".swiper-pagination",
					// 	type: "progressbar",
					// },
					// loop: true,
					// slidesPerView: "auto",
					// autoplay: true
					// Some Swiper option/callback...
				},
				activeIndex: 1,
			};
		},
		methods: {
			// topre() {
			// 	this.swiper.slidePrev();
			// },
			// tonext() {
			// 	this.swiper.slideNext();
			// },
			goto() {
				this.$router.push({ name: "Partners" });
			},
		},
		components: {},
		computed: {
			// swiper() {
			// 	return this.$refs.mySwiper.$swiper;
			// },
		},
		mounted() {},
	};
</script>