<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
	<div class="result-card" @click="gotoDeatil(item)">

		<div class="result-title-row">
			<div class="result-title" v-html="item.namex">
			</div>
			<div class="result-tag">{{ item.source }}</div>
		</div>

		<div class="result-content" v-html="item.intro">
		</div>
	</div>
</template>
<style scoped>





.result-title-row {

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.result-title {
	font-size: 30px;
	font-weight: bolder;
	margin: 10px 0
}

.result-tag {
	background: rgba(42, 143, 202, 1);
	color: white;
	width: 140px;
	height: 40px;
	line-height: 40px;
	font-size: 20px;
	box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
	border-radius: 4px 4px 4px 4px;
}

.result-card {
	background: rgba(245, 245, 245, 1);
	border-radius: 8px 8px 8px 8px;
	padding: 40px;
	cursor: pointer;
	margin-top: 20px;
}

.result-content {
	text-align: left;
	border-top: 1px solid rgba(214, 214, 214, 0.6);
}
</style>
<script>
// @ is an alias to /src

export default {
	name: "resultItem",
	props: {
		item: Object,
	},
	components: {},
	data() {
		return {};
	},
	watch: {},
	methods: {
		gotoDeatil(item) {
			this.$router.push({ name: "ProductDetail", params: { id: item.pageId } });//
		},

	},

	computed: {},
	mounted() { },
	created() { },
};
</script>
