<template>
  <div>
    <vHeader @showSearch="showSearch" class="header"></vHeader>

    <!-- <img src="../../assets/contact_banner.jpg" class="page-banner" /> -->
    <vBanner
      :pagePath="pagePath"
      :showSearch="searchVisible"
      class="page-banner"
    ></vBanner>

    <div class="page-content">
      <h2>{{ currentNews.title }}</h2>

      <div class="tool-row">
        <div class="date-col">
          <!-- May. 05. 2023 -->
        </div>
        <div class="share-col">
          <social-sharing
            :url="url"
            :title="currentNews.title"
            :description="currentNews.desc"
            :quote="currentNews.desc"
            hashtags="Chempharms,food"
            inline-template
          >
            <div>
              <network network="facebook">
                <img src="../../assets/icon_facebook.png" class="icon2" />
              </network>

              <network network="linkedin">
                <img src="../../assets/icon_linkedin.png" class="icon2" />
              </network>

              <!-- 
              <network network="pinterest">
                <i class="fa fa-pinterest"></i> Pinterest
              </network>
              <network network="reddit">
                <i class="fa fa-reddit"></i> Reddit
              </network>
              <network network="twitter">
                <i class="fa fa-twitter"></i> Twitter
              </network>
              <network network="vk">
                <i class="fa fa-vk"></i> VKontakte
              </network>
              <network network="weibo">
                <i class="fa fa-weibo"></i> Weibo
              </network>
              <network network="whatsapp">
                <i class="fa fa-whatsapp"></i> Whatsapp
              </network> -->
            </div>
          </social-sharing>
        </div>
      </div>
      <div class="news-content" v-html="currentNews.content"></div>
    </div>
  </div>
</template>
<style scoped>
@media (max-width: 767.98px) {
  .page-content h2 {
    line-height: 40px !important;
  }
}

.tool-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-bottom: 1px solid rgba(228, 228, 228, 1);
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.share-col {
  display: flex;
}

.news-content {
  text-align: left;
}

.page-content {
  /* width: initial; */
  margin-top: 120px;
  margin-bottom: 120px;
}

@import "../../common/common.css";
</style>

<style>
.news-content img {
  max-width: 100% !important;
}
.icon2 {
  width: 36px;
  vertical-align: middle;
  margin: 0;
  margin-left: 20px;
}
</style>
<script>
import { getNews } from "@/api/news";
import vBanner from "../Common/banner.vue";

import vHeader from "../Common/header";
export default {
  data() {
    return {
      pagePath: "news",
      currentNews: {},
      searchVisible: false,
      url: encodeURI(window.location.href)
    };
  },
  computed: {},
  components: { vHeader, vBanner },
  methods: {
    showSearch() {
      this.searchVisible = true;
    },
    async fetchData() {
      const res = await getNews({ id: this.$route.params.id });
      console.log("\x1b[42m%s\x1b[0m", "news_content.vue line:131 res", res.data);
      res.data.items.forEach((d) => {
        if (d.id == this.$route.params.id) this.currentNews = d;
      });

      console.log(this.currentNews);
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
