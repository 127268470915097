<template>
	<el-container style="background: black; color: white;position:relative">
		<vHeader :isHome="true" @showSearch="showSearch" class="header"></vHeader>

		<swiper ref="mySwiperMain" class="main-page-swiper bg" :options="swiperOptions">
			<swiper-slide class="" v-for="s in homeList" v-bind:key="s.id">
				<div class="swiper-holder ">
					<img :src="s.thumb" class="home-img">
				</div>
			</swiper-slide>
		</swiper>

		<div class="main-page">
			<div class="main-messager" @click="goto('About')">
				Learn More
			</div>
			<div class="main-holder">
				<div class="main-title" v-html="datas.home_title">
				</div>
				<div class="main-desc" v-html="datas.home_text">
				</div>
				<div class="btn-more" @click="goto('ContactUs')">
					GET A QOUTE
				</div>
			</div>

			<!--
			<div class="swiper-tools">
				<i class="el-icon-arrow-left swiper-btn"></i>
				<div class="active-index">{{ activeIndex }}/6</div>
				<i class="el-icon-arrow-right swiper-btn"></i>
			</div> -->

		</div>

	</el-container>
</template>


<style>

.main-title p{
	margin:0 !important;
}
.main-desc p{
	margin:0 !important;
}
@import "../../common/common.css";
</style>





<style scoped>
.btn-more{
	margin-top:60px
}
.home-img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.swiper-btn {
	margin-left: 30px;
	margin-right: 30px;
}

.swiper-tools {
	position: absolute;
	right: 10px;
	bottom: 10px;
	font-size: 30px;
	display: flex;
	align-items: center;
}

@import "../../common/flexible/main.css";


.main-page-swiper {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

.main-page {
	position: relative;
	height: 100%;
	padding: 0;
	width: 100%;
	z-index: 2;
	overflow: hidden !important;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
}

.main-messager {
	cursor: pointer;
	font-size: 24px;
	font-family: Arial;
	font-weight: 400;
	line-height: 40px;
	color: #2A8FCA;
	opacity: 1;
	width: 60px;
	height: 280px;
	background: #FFFFFF;
	box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
	opacity: 1;
	border-radius: 40px 0px 0px 40px;
	writing-mode: vertical-rl;
	/* 竖排从右向左 */
	text-orientation: sideways;
	/* 保留横向字符方向 */
	white-space: nowrap;
	/* 禁止自动换行 */
	position: absolute;
	right: 0;
	top: 30%;
}

.main-holder {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: center;
	align-items: center;
	background-size: cover
}



.main-desc {
	/* height: 30px; */
	font-size: 26px;
	font-family: Arial;
	font-weight: 400;
	line-height: 40px;
	color: #FFFFFF;
	text-shadow: 0px 2px 6px rgba(16, 90, 114, 0.36);
	opacity: 1;
}

.main-title {
	font-size: 80px;
	font-family: Arial;
	font-weight: bold;
	line-height: 100px;
	color: #FFFFFF;
	text-shadow: 0px 2px 6px rgba(16, 90, 114, 0.36);
	opacity: 1;
}


.main-pic {
	width: 99%;
	vertical-align: middle;
}

.main-txt {
	vertical-align: middle;
	text-align: left;
	width: 100%;
	z-index: 5;
	position: relative;
}

.main-txt h1 {
	font-size: 70px;
	width: 135%;
}

.main-txt h3 {
	font-size: 30px;
}

.main-txt h4 {
	padding-top: 48px;
	font-size: 24px;
	width: 90%;
}

.flex-middle {
	display: flex;
	justify-content: center;
	/*x轴对齐方式*/
	align-items: center;
	/*y轴对滴方式*/
}

.bg {
	background-image: url('../../assets/home_p1.jpg');
	background-size: cover;
}

 
</style>

<script>
import { getList } from "@/api/homepage";
import vHeader from "../Common/header";

export default {
	name: "mainHolder",
	props: {

		datas: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			homeList: [],
			swiperOptions: {
				pagination: {
					el: ".swiper-pagination",
					type: "progressbar",
				},
				loop: true,
				autoplay: true,

				// Some Swiper option/callback...
			},
			activeIndex: 1,

			home_title: '',
			home_text: '',
		};
	},
	methods: {

		async fetchData() {
			this.listLoading = true;
			const response = await getList();
			this.homeList = response.data.items;
			this.listLoading = false;
		},
		showSearch() {
			// this.$router.push({ name: "Search", params: { keyword: '' } });
			this.showSearch
		},
		goto(path) {
			this.$router.push({
				name: path,
			});
		}
	},
	components: {
		vHeader,

		// Welcome,
	},
	computed: {
		// swiper() {
		// 	return this.$refs.mySwiper.$swiper;
		// },
	},
	mounted() {
		this.fetchData();
		// setTimeout(function () {
		// 	document.getElementsByClassName(
		// 		"swiper-pagination-progressbar-fill"
		// 	)[0].style.transform = "translate3d(0px, 0px, 0px) scaleX(0.2) scaleY(1)";
		// }, 500);
	},
};
</script>