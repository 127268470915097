<template>
	<el-container>
		<el-main class="section-page">
			<div class="page">
				<div class="bg-big-txt">SERVICE</div>

				<div class="section-inner page-service">
					<el-row :gutter="10">
						<el-col
							:xs="24"
							:sm="24"
							:md="12"
							:lg="12"
							:xl="12"
							class="section-parts section-mobile"
						>
							<!-- <img src="../../assets/service_main.jpg" class="section-pic" /> -->
							<img :src="datas.service_pic" class="section-pic" />


						</el-col>

						<el-col
							:xs="24"
							:sm="24"
							:md="12"
							:lg="12"
							:xl="12"
							class="section-parts"
						>
							<h1 v-html="datas.service_title"></h1>
							<h4  v-html="datas.service_text">
							</h4>

							<div class="btn-more-reverse" @click="goto">LEARN MORE ></div>
						</el-col>

						<el-col
							:xs="24"
							:sm="24"
							:md="12"
							:lg="12"
							:xl="12"
							class="section-parts section-pc"
						>
							<img src="../../assets/service_main.jpg" class="section-pic" />
						</el-col>
					</el-row>
				</div>
			</div>
		</el-main>
	</el-container>
</template>
<style scoped>
	.section-pic {
		width: 800px;
	}

	.btn-more-reverse {
		margin-top: 150px;
	}

	@import "../../common/flexible/home_service.css";
</style>
<script>
	export default {
		name: "serviceHolder",
		props: {
		datas: {
			type: Object,
			required: true,
		},},
		data() {
			return {
				swiperOptions: {
					// pagination: {
					// 	el: ".swiper-pagination",
					// 	type: "progressbar",
					// },
					// loop: true,
					// slidesPerView: "auto",
					// autoplay: true
					// Some Swiper option/callback...
				},
				activeIndex: 1,
			};
		},
		methods: {
			// topre() {
			// 	this.swiper.slidePrev();
			// },
			// tonext() {
			// 	this.swiper.slideNext();
			// },
			goto() {
				this.$router.push({ name: "Services" });
			},
		},
		components: {},
		computed: {
			// swiper() {
			// 	return this.$refs.mySwiper.$swiper;
			// },
		},
		mounted() {},
	};
</script>