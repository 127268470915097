<template>
	<div class="side-list">
		<div v-for="category in cateList" :key="category.id">
			<div
				@click="goto(category.label)"
				class="list-parent"
				:class="{ 'parent-active': category.id == currentParent.id }"
			>
				<div class="icon-txt-holder">
					<div v-html="icons[category.label]" class="parent-icon"></div>

					<div class="parent-txt">
						{{ category.label }}
					</div>
				</div>

				<div class="arrow-holder">
					<i
						v-if="category.id == currentParent.id"
						class="el-icon-caret-top"
					></i>
					<i v-else class="el-icon-caret-bottom"></i>
				</div>
			</div>

			<div
				v-if="category.id == currentParent.id"
				class="cate-holder"
				:class="getCateClass(category)"
			>
				<div v-for="(cate, i) in category.children" :key="cate.id">
					<div
						:style="{
							borderBottom: i === category.children.length - 1 ? 'none' : '',
						}"
						@click="goto(cate.id)"
						class="list-cate"
						:class="{ 'cate-active': cate.id == currentCate.id }"
					>
						{{ cate.label }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style>
	@import "../../common/common.css";
</style>

<style scoped>
	@media only screen and (max-width: 768px) {
		.hide-in-mobile {
			display: none !important;
		}
	}
	.arrow-holder {
		margin-right: 40px;
	}
	.icon-txt-holder {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		align-items: center;
		margin-left: 40px;
	}
	.parent-txt {
		margin-left: 30px;
		width: 200px;
		text-align: left;
		/* background:yellow; */
		height: 100%;
	}

	.parent-icon {
		width: 40px;
		display: flex;
		margin-left: 10px;
		height: 40px;
	}

	.parent-icon svg {
		width: 100%;
	}

	.parent-active {
		width: 100%;
	}

	.cate-holder {
		width: 100%;
		background: rgba(235, 245, 251, 1);
	}

	.cate-active {
		font-family: Arial;
		font-weight: bold !important;

		color: #2a8fca !important;
	}

	.list-cate {
		height: 80px;
		font-size: 18px;
		font-family: Arial;
		font-weight: 400;
		line-height: 27px;
		color: #4a4a4a;
		opacity: 1;
		line-height: 80px;
		margin-left: 100px;
		border-bottom: solid rgba(42, 143, 202, 0.2) 1px;
		text-align: left;
		width: 260px;
		cursor: pointer;
	}

	.list-parent {
		height: 80px;
		width: 100%;
		background: #cceafc;
		opacity: 1;
		/* border-radius: 4px 4px 0px 0px; */
		font-size: 22px;
		font-family: Arial;
		font-weight: bold;
		line-height: 73px;
		color: #2a8fca;
		opacity: 1;
		line-height: 80px;
		/* border: 1px solid #EBF5FB; */
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: space-around;
		align-items: center;
		cursor: pointer;
		justify-content: space-between;
	}

	.parent-active {
		color: #ffffff;
		background: #2a8fca;
	}

	.side-list {
		width: 400px;
		/* background: green; */
	}
</style>
<script>
	import contents from "../../utils/contents";

	export default {
		name: "sideList",
		props: {
			cateList: {
				type: Array,
				required: true,
			},
			currentParent: {
				type: Object,
				required: true,
			},
			currentCate: {
				type: Object,
				required: true,
			},
		},
		data() {
			return {
				svgColor: "red",
				icons: contents.icons,
			};
		},
		methods: {
			getCateClass(cate) {
				if (this.currentCate.id == this.currentParent.id) {
					//当前选中的是大类，展开二级菜单
					return "";
				} else {
					return "hide-in-mobile";
				}
			},
			// topre() {
			//   this.swiper.slidePrev();
			// },
			// tonext() {
			//   this.swiper.slideNext();
			// }
			goto(cate) {
				this.$router.push({ name: "Application", params: { cate: cate } });
			},
		},
		components: {},
		computed: {
			// swiper() {
			//   return this.$refs.mySwiper.$swiper;
			// }
		},
		mounted() {

		},
	};
</script>
