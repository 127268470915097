<template>
	<div class="home">

		<div class="">
			<vNews class="page-minheight"></vNews>
			<vFooter></vFooter>
		</div>

	</div>
</template>
<script>
// @ is an alias to /src

import vNews from "../components/News/news.vue";

import { uuid } from "vue-uuid";
import vFooter from "../components/Common/footer.vue";

export default {
	name: "HomePage",
	components: { vNews, vFooter },
	data() {
		return {};
	},
	watch: {},
	methods: {
		handleTouchStart: function () {
			var obj = {
				show: true,
				uuid: uuid.v4(),
			};
			this.$store.commit("setShowTip", obj);
		},
	},
	computed: {},
	mounted() {
		var widthL = document.body.clientWidth;
	},
};
</script>
