<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
	<div class="detail-header-row">
		<el-row :gutter="10">
			<el-col class="image-row-holder" :span="12" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
				<div class="grid-content bg-purple image-holder">
					<div class="image-display">
						<img :src="currentImage" alt="Main Image" class="main-image" v-disable-right-click />
						<img src="../../../assets/watermark.png" class="product-watermark">
					</div>
					<div class="thumbnails">
						<img v-disable-right-click v-for="(image, index) in product.imageList || []" :key="index"
							:src="image" alt="Thumbnail" class="thumbnail" @click="changeImage(index)" />
					</div>
				</div>
			</el-col>

			<el-col :span="12" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
				<div class="grid-content bg-purple">
					<h2 class="title">{{ product.name }}</h2>
					<div class="description" v-html="product.summary"></div>

					<div class="table">
						<div class="row" v-for="(item, index) in productDetails" :key="index" v-if="product[item.key]">
							<div class="cell cell-left">{{ item.label }}</div>
							<div class="cell">{{ product[item.key] }}</div>
						</div>
					</div>

					<div @click="leaveMessage" class="btn-more-reverse">
						Leave Message
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>
<style scoped>
.product-watermark {
	width:100%;
	position: absolute;
	z-index: 100;
	bottom: 0;
	left: 0;

}

.title {
	font-size: 30px;
	font-family: Arial-Bold, Arial;
	font-weight: bold;
	color: #4a4a4a;
	text-align: left;
	margin-top: 0;
}

.description {
	font-size: 20px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #4a4a4a;
	text-align: left;
}

.btn-more-reverse {
	width: 100%;
	margin-top: 60px;
}

.detail-header-row {
	width: 100%;
}

.image-row-holder {
	display: flex;
	justify-content: center;
}

.table {
	display: flex;
	flex-direction: column;
	border: 1px solid #ccc;
	margin-top: 60px;

	border-radius: 10px;
	overflow: hidden;
}

.cell-left {
	background: rgba(247, 247, 248, 1);
	font-size: 20px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #9b9b9b;
	line-height: 40px;
	text-align: left;
}

.row {
	display: flex;
	border-top: 1px solid #ccc;
}

.row:first-child {
	border-top: 0;
}

.cell {
	font-size: 20px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #4a4a4a;
	line-height: 40px;
	flex: 1;
	text-align: left;
	flex: 1;
	padding: 10px;
	border-bottom: 1px solid #ccc;

	padding: 10px;
}

.cell-left {
	font-weight: bold;
	border-right: 1px solid #ccc;
}

.row:last-child .cell {
	border-bottom: 0;
}

.image-display {
	width: 540px;
	height: 540px;
	overflow: hidden;
	position:relative;
	background:#DBDBDD;
}

.main-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.thumbnails {
	display: flex;

	justify-content: flex-start;
	width: 100%;
	margin-top: 12px;
}

.thumbnail {
	cursor: pointer;
}

.thumbnail {
	width: 126px;
	height: 126px;
	object-fit: cover;
	margin-right: 12px;
}

.image-holder {
	width: 540px;
}
</style>
<script>
// @ is an alias to /src


export default {
	name: "DetailHeadRow",
	props: {
		product: Object,
	},
	components: {},
	data() {
		return {
			currentImage: "",
			productDetails: [


				{ key: "Type", label: "Type" },
				{ key: "Brand", label: "Brand" },
				{ key: "eNo", label: "E No." },
				{ key: "cas", label: "CAS No." },
				{ key: "einecs", label: "EINECS No." },
				{ key: "hsCode", label: "HS Code" },
				{ key: "qty", label: "Qty in 20' FCL" },
				{ key: "routing", label: "Routine Packing" },
				{ key: "minOrder", label: "Min. Order" },
				// Other product details
			],
		};
	},
	watch: {
		product: function () {
			this.changeImage(0);
		},
	},
	methods: {
		leaveMessage() { this.$emit('scrollToContactForm'); },
		changeImage(index) {
			this.currentImage = this.product.imageList[index];
		},
	},

	computed: {},
	mounted() { },
	created() { },
};
</script>
