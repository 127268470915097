<template>
	<div class="banner-holder">

		<el-image class="banner" :src="imageUrl" fit="cover">
			<div slot="placeholder" class="banner-slot-holder">
				<i class="el-icon-loading"></i>
			</div>
			<div slot="error" class="banner-slot-holder">
				<i class="el-icon-loading"></i>
			</div>
		</el-image>

	</div>
</template>

<style scoped>


.banner-slot-holder {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.banner-holder {
	position: relative;
	;
}




.icon-empty {
	/* 未输入内容时的图标样式 */
	background-color: #ccc;
}

.icon-filled {
	/* 输入内容时的图标样式 */
	background-color: #333;
}

.banner {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
</style>

<script>
import { getBanner } from "@/api/banner"; //假设@api/banner就是这个地址
export default {
	name: "vBanner",

	data() {
		return {
			imageUrl: "", // 存储获取到的图片地址
			keywords: '',
		};
	},
	props: {
		pagePath: String,
		imgPath: String,
		showSearch: Boolean
	},
	watch: {
		pagePath(newVal) {
			this.fetchBanner();
		},
		imgPath() {

			this.fetchBanner();
		},
	},
	methods: {
		async fetchBanner() {

			if (this.imgPath) {
				this.imageUrl = this.imgPath;
				console.log(this.imgPath)
			} else {
				const res = await getBanner();
				res.data.forEach((d) => {
					if (d.pagePath == this.pagePath) this.imageUrl = d.content;
				});
			}

		},


	},
	created() {
		this.fetchBanner(this.pagePath);
	},
};
</script >
