<template>
	<div class="home">

		<div class="">
			<vService class="page-minheight"></vService>
			<vFooter></vFooter>
		</div>

		<!-- </el-col>
		<el-col id="test" class="home_col" :span="24" :xs="24" :sm="0" :md="0" :lg="0" :xl="0">
			<div @touchend="handleTouchStart">
				<vAbout id="page1"></vAbout>
				<vFooter></vFooter>
			</div>
		</el-col> -->
	</div>
</template>

<style>

@import "../common/flexible/fixheight.css";

html::-webkit-scrollbar,
body::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

body {
	margin: 0;
}


.iScrollIndicator {
	background-color: transparent !important;
	border: 0 !important;
}

.section {
	height: 100%;
	overflow-y: hidden;
}

.section .el-container {
	height: 100%;
}

@media screen and (max-width: 768px) {
	#app {
		height: 100%;
	}

	.home {
		height: 100%;
		letter-spacing: -1px;
	}

	.home_div {
		height: 100%;
	}

	.home_col {
		position: absolute;
		height: 100%;
		overflow: auto;
		z-index: 99999;
		-webkit-overflow-scrolling: touch;
	}

	#test::-webkit-scrollbar {
		display: none;
	}

	.section {
		height: auto !important;
	}

	.phonePage {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}

	#page3 {
		margin-top: 60px;
	}
}
</style>
<script>
// @ is an alias to /src

import vService from "../components/Service/service.vue";

import { uuid } from "vue-uuid";
import vFooter from "../components/Common/footer.vue";

export default {
	name: "HomePage",
	components: { vService, vFooter },
	data() {
		return {};
	},
	watch: {},
	methods: {
		handleTouchStart: function () {
			var obj = {
				show: true,
				uuid: uuid.v4(),
			};
			this.$store.commit("setShowTip", obj);
		},
	},
	computed: {},
	mounted() {
		var widthL = document.body.clientWidth;
	},
};
</script>
