<template>
	<div v-html="html"></div>
</template>

<style>



@media only screen and (max-width:768px) {

	.certification img {
		/* width: 230px !important; */
		width: 45% !important;
		margin: 2%;
		flex-grow: 1;
	}

}
	.certification {
		display: flex;
		width: 100%;
		margin-bottom: 100px;    flex-wrap: wrap;
	}
	.certification img {
		/* width: 230px !important; */
		width: 22%;
		margin: 1%;
		flex-grow: 1;
	}

	.fake-table {
		width: 100%;
	}

	.table-tr {
		vertical-align: text-bottom;
		text-align: left;
	}

	.table-title {
		width: 350px;
		font-size: 24px;
		font-family: Arial;
		font-weight: bold;
		line-height: 40px;
		color: #333333;
		opacity: 1;
	}

	.table-content {
		font-size: 24px;
		font-family: Arial;
		font-weight: 400;
		line-height: 40px;
		color: #333333;
		opacity: 0.6;
	}
</style>






<style scoped>
	.banner {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
</style>

  <script>
	import { getContent } from "@/api/content";
	export default {
		name: "vContent",
		data() {
			return {
				html: "", // 存储获取到的图片地址
			};
		},
		props: {
			pagePath: String,
		},
		watch: {
			pagePath(newVal) {
				console.log(newVal);
				this.fetchContent(newVal);
			},
		},
		methods: {
			async fetchContent() {
				const res = await getContent();
				res.data.forEach((d) => {
					if (d.pagePath == this.pagePath) this.html = d.content;
				});
			},
		},
		created() {
			this.fetchContent(this.pagePath);
		},
	};
</script >
