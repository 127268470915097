<template>
	<div class="home">
		<div class="">
			<vProduct :currentParent="currentParent"></vProduct>

			<div class="product-list">
				<div class="sidebar">
					<vSideList :cateList="sidebarData" :currentCate="currentCate" :currentParent="currentParent">
					</vSideList>
					<vHelper class="helper-magrin" @scrollToContactForm="scrollToContactFormHandler"></vHelper>
				</div>

				<!-- 左侧侧边栏-->
				<div class="content-holder">
					<!-- 右侧内容区域-->
					<div class="content">
						<DetailHeadRow @scrollToContactForm="scrollToContactFormHandler" :product="currentProduct">
						</DetailHeadRow>
						<div class="text-contents">
							<div class="tab-holder">
								<div :class="{ 'tab-btn-active': index == currentTab }" @click="chooseTab(t.key)"
									v-for="(t, index) in tabs" v-bind:key="index" class="tab-btn">
									{{ t.label }}
								</div>
							</div>

							<div class="txt-content">
								<div v-for="(t, index) in tabs" v-bind:key="index" v-bind:ref="`${t.key}Ref`">
									<h2>{{ t.label }}</h2>
									<div v-html="currentProduct[t.key]"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="page-form aqa-bg">
				<relatedProducts :productList="relatedList"></relatedProducts>
			</div>

			<div class="page-form gray-bg" ref="contactForm">
				<ContactForm></ContactForm>
			</div>
			<vFooter></vFooter>
		</div>
	</div>
</template>

<style>
@media only screen and (max-width: 768px) {
	.content-holder {
		margin-top: 40px;
	}

	.news-holder {
		display: flex;
		align-content: center;
		justify-content: center;
	}
}

.txt-content h2 {
	font-size: 22px;
	margin-top:67px
}

.aqa-bg {
	background-color: rgba(235, 245, 251, 1);
	position: relative;
}

.txt-content p {
	font-size: 20px;
	color: #4a4a4a !important;
}

.txt-content {
	color: #4a4a4a !important;
}

.txt-content table {
	background-color: transparent !important;
	font-size: 20px;
}

.txt-content table td+td {
	margin-left: 4px;
}

/* 设置单元格的背景颜色为白色，同时添加4px的缩进效果 */
.txt-content table td {
	border-radius: 8px;

	background-color: rgba(247, 247, 248, 1);
	padding: 8px;
	padding-left: 30px;
}

.txt-content table tr {
	border-radius: 8px;
	height: 80px;
}

table tr {
	height: 40px;
}

/* 设置表头的背景颜色为蓝色 */
.txt-content table tr:first-child td {
	background-color: rgba(235, 245, 251, 1);
}

.text-contents {
	width: 100%;
	text-align: left;
	margin-top: 30px;
}

.tab-holder {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	width: 100%;
	align-items: baseline;
	justify-content: space-evenly;
}

.tab-btn {
	font-size: 22px;
	font-family: Arial-Regular, Arial;
	font-weight: 400;
	color: #4a4a4a;
	height: 80px;
	background: #f7f7f7;
	flex: 1;
	/* 使用 flex 属性平分宽度 */
	text-align: center;
	/* 文字居中 */
	line-height: 80px;
	cursor: pointer;
}

.tab-btn-active {
	background: #ebf5fb;
	font-size: 22px;
	font-family: Arial-Bold, Arial;
	font-weight: bold;
	color: #2a8fca;
}

.current-path {
	/* width: 100%; */
	text-align: center;
	font-size: 30px;
	font-family: Arial-Bold, Arial;
	font-weight: bold;
	color: #2a8fca;
	line-height: 80px;
	padding: 30px;
}

.helper-magrin {
	margin-top: 40px;
}

.content-holder {
	position: relative;
	width: 100%;
	padding-bottom: 100px;
}

.pagination-holder {
	position: absolute;
	right: 0;
	bottom: 0;
}

.card-label-title {
	display: block;
	text-align: left;
	width: 120px;

	padding-right: 10px;

	font-size: 18px;
	font-family: Arial;
	font-weight: 400;

	color: #9b9b9b;
	opacity: 1;
}

.card-label {
	display: flex;
	margin-bottom: 5px;
}

.card-label-value {
	display: block;
	flex-grow: 1;
	color: #4a4a4a;
}

.card-label {
	height: 25px;
	text-align: left;
	font-size: 18px;
	font-family: Arial;
	font-weight: 400;
	color: #9b9b9b;
	opacity: 1;
}

.card-title {
	font-size: 24px;
	text-align: left;
	font-family: Arial;
	height: 90px;
	font-weight: bold;
	line-height: 30px;
	color: #4a4a4a;
	margin: 15px 0 15px 0;
	opacity: 1;
}

.card-content {
	margin: 26px;
}

.item-card {
	width: 380px;
	height: 580px;
	padding: 0;
	background: white;
	margin: 0px 20px 60px 20px;
	box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.12);
}

.img-holder {
	width: 100%;
	height: 380px;
}

.card-img {
	width: 100%;
	height: 100%;
}

.product-list {
	padding: 120px;
	padding-top: 0px;
	/* background: yellow; */
	display: flex;
	position: relative;
	margin-top: 140px;
}

.sidebar {
	width: 400px;
	height: 100%;
}

.content {
	width: 100%;
	height: 100%;
	margin-left: 30px;
	/* background: green; */
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: flex-start;
	justify-content: flex-start;
	align-items: flex-start;
}

@import "../common/flexible/product_app.css";
</style>
<style>


html::-webkit-scrollbar,
body::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

body {
	margin: 0;
}



.iScrollIndicator {
	background-color: transparent !important;
	border: 0 !important;
}

.section {
	height: 100%;
	overflow-y: hidden;
}

.section .el-container {
	height: 100%;
}

@media screen and (max-width: 768px) {
	#app {
		height: 100%;
	}

	.home {
		height: 100%;
		letter-spacing: -1px;
	}

	.home_div {
		height: 100%;
	}

	.home_col {
		position: absolute;
		height: 100%;
		overflow: auto;
		z-index: 99999;
		-webkit-overflow-scrolling: touch;
	}

	#test::-webkit-scrollbar {
		display: none;
	}

	.section {
		height: auto !important;
	}

	.phonePage {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}

	#page3 {
		margin-top: 60px;
	}
}
</style>

<style scoped>
.page-content h1 {
	padding-top: 140px;
}
</style>


<script>
// @ is an alias to /src

import { getCateList, getList } from "@/api/products";
import vFooter from "../components/Common/footer.vue";
import vHelper from "../components/Common/help.vue";
import vSideList from "../components/Product/category_list.vue";
import vProduct from "../components/Product/product.vue";

import ContactForm from "../components/Common/contactForm.vue";
import DetailHeadRow from "../components/Common/product/headRow.vue";
import productItem from "../components/Common/product/productItem.vue";
import relatedProducts from "../components/Common/product/relatedProducts.vue";

export default {
	name: "ProductList",
	components: {
		vSideList,
		vProduct,
		vFooter,
		vHelper,
		DetailHeadRow,
		ContactForm,
		productItem,
		relatedProducts,
	},
	data() {
		return {
			relatedList: [],
			currentHtml: "",
			tabs: [
				{ label: "DESCRIPTION", key: "description" },
				{ label: "SPECFICATION", key: "specfication" },
			],
			currentTab: 0,
			currentCate: {},
			currentParent: {},

			currentProduct: {},
			cateList: [],
			itemList: [],
			sidebarData: [],
		};
	},
	watch: {
		"$route.params.id"(newCate) {
			this.fetchData();
			this.currentPage = 1; // 切换分类时回到第一页
		},
	},
	methods: {
		scrollToContactFormHandler() {
			this.$refs.contactForm.scrollIntoView({ behavior: "smooth" });
		},
		chooseTab(tabKey) {
			this.currentTab = tabKey;
			this.$refs[`${tabKey}Ref`][0].scrollIntoView({ behavior: "smooth" });
		},
		async fetchData() {
			this.listLoading = true;

			const response1 = await getList();
			const response2 = await getCateList();

			this.itemList = response1.data.items;
			this.cateList = response2.data.items;

			this.formatData(this.cateList);

			const { id } = this.$route.params; // 获取路由参数
			this.currentProduct = this.itemList.find((item) => item.id === id);

			if (this.currentProduct.related) {
				let relatedIdList = [];
				let relatedList = [];
				this.currentProduct.related.forEach((p) => {
					relatedIdList = relatedIdList.concat(p);
				});

				this.itemList.forEach((p) => {
					if (relatedIdList.indexOf(p.id) > -1) {
						relatedList.push(p);
					}
				});

				this.relatedList = relatedList;
			}

			// this.chooseTab(0);
			if (!this.currentProduct) {
				this.listLoading = false;
				return;
			}

			// 根据产品的 category 查找当前目录和父目录
			const currentCateId = this.currentProduct.catgory[1];
			this.currentCate = this.cateList.find(
				(cate) => cate.id === currentCateId
			);

			if (this.currentCate.level === "Root") {
				this.currentParent = { ...this.currentCate };
			} else if (this.currentCate.level === "Sub") {
				this.currentParent = this.cateList.find(
					(cate) => cate.id === this.currentCate.parent
				);
			}

			this.listLoading = false;
		},

		handlePageChange(page) {
			this.currentPage = page;
			this.fetchData();
		},

		formatData(rawData) {
			// 分类数据
			const roots = [];
			const subs = {};
			rawData.forEach((item) => {
				if (item.level === "Root") {
					item.children = [];
					roots.push(item);
				} else if (item.level === "Sub") {
					if (!subs[item.parent]) {
						subs[item.parent] = [];
					}
					subs[item.parent].push(item);
				}
			});

			// 将子项添加到父项中
			Object.keys(subs).forEach((parent) => {
				const root = roots.find((item) => item.id === parent);
				if (root) {
					root.children = subs[parent];
				}
			});

			// 排序根项目
			this.sidebarData = roots.sort((a, b) => a.label.localeCompare(b.order));
			// 生成表格数据
			// this.sidebarData = roots.reduce((pre, cur) => {
			//   return pre.concat({
			//     ...cur
			//   }, cur.children.map(item => {
			//     return {
			//       ...item
			//     }
			//   }))
			// }, [])
		},
	},
	computed: {},
	mounted() {
		this.fetchData();
		// this.loadProducts(this.$route.params.cateid);

		// 监听窗口尺寸变化，重新初始化 Swiper
	},
	created() { },
};
</script>
