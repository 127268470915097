<template>
  <div class="home">
    <!-- <el-col :span="24" :xs="0" :sm="24" :md="24" :lg="24" :xl="24"> -->
    <full-page ref="fullpage" :options="options" id="fullpage">
      <div class="section page-main">
        <vMain :datas="datas" style="height: 100%"></vMain>
      </div>
      <div class="section page-product">
        <vProduct :datas="datas"></vProduct>
      </div>

      <div class="section page-clients">
        <vClients :datas="datas"></vClients>
      </div>

      <div class="section page-service">
        <vService :datas="datas"></vService>
      </div>
      <div class="section page-news" id="footer-about">
        <vNews style="height: 100vh"></vNews>
        <vFooter></vFooter>
      </div>
    </full-page>
  </div>
</template>
<style>
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

body {
  margin: 0;
}


.iScrollIndicator {
  background-color: transparent !important;
  border: 0 !important;
}

.section {
  height: 100%;
  overflow-y: hidden;
}

.section .el-container {
  height: 100%;
}

@media screen and (max-width: 768px) {
  #app {
    height: 100%;
  }

  .home {
    height: 100%;
    letter-spacing: -1px;
  }

  .home_div {
    height: 100%;
  }

  .home_col {
    position: absolute;
    height: 100%;
    overflow: auto;
    z-index: 99999;
    -webkit-overflow-scrolling: touch;
  }

  #test::-webkit-scrollbar {
    display: none;
  }

  .section {
    height: auto !important;
  }

  .phonePage {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  #page3 {
    margin-top: 60px;
  }
}
</style>
<script>
// @ is an alias to /src
import vMain from "../components/Home/main.vue";

import vClients from "../components/Home/clients.vue";
import vProduct from "../components/Home/product.vue";
import vService from "../components/Home/service.vue";

import vNews from "../components/Home/news.vue";

import { getContent } from "@/api/content";
import { uuid } from "vue-uuid";
import vFooter from "../components/Common/footer.vue";
export default {
  name: "HomePage",
  components: { vMain, vProduct, vClients, vService, vNews, vFooter },
  data() {
    return {
      options: {
        scrollOverflow: true,
        licenseKey: "e2AsHyB&o4",
        menu: "#menu",
        // anchors: ["main", "product", "innovative", "about"],
        // sectionsColor: ["black", "white", "#0798ec"]
      },
      datas: {},

    };
  },
  watch: {
    index(val) {
      //普通的watch监听
      this.toPages(val);
    },
    indexs(val) {
      //普通的watch监听
      this.toPages1(val);
    },
  },
  methods: {
    toPages: function (item) {
      this.$refs.fullpage.api.moveTo(item.index);
    },
    toPages1: function (key) {
      this.$refs.fullpage.api.destroy();
      var PageId = document.querySelector("#page" + key.index);
      document.querySelector("#test").scrollTo({
        top: PageId.offsetTop - 60,
        behavior: "smooth",
      });
    },
    handleTouchStart: function () {
      var obj = {
        show: true,
        uuid: uuid.v4(),
      };
      this.$store.commit("setShowTip", obj);
    },
    async fetchContent() {
      const convertedData = {};

      const res = await getContent();

      res.data.forEach((item) => {
        convertedData[item.pagePath] = item.content;
      });

      this.datas = convertedData
    },
  },
  computed: {
    index: function () {
      return this.$store.state.indexNum;
    },
    indexs: function () {
      return this.$store.state.indexNums;
    },
  },
  mounted() {

    this.fetchContent();
    // if (widthL <= 768) {
    // 	this.$refs.fullpage.api.destroy();
    // }
  },
};
</script>
