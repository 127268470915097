import request from '@/utils/request'
import axios from 'axios'

let baseUrl = `${window.location.protocol}//${window.location.host}/contents/`;

// 获取产品列表
export function getList(params) {

  if (process.env.VUE_APP_CONFIG_WAY == 'JSON') {
    let url = baseUrl + 'applications.json?t=' + new Date().getTime()
    return axios.get(url)
      .then(response => {
        return {
          data: {
            items: response.data
          }
        }
      })
      .catch(error => {
        console.error('Error fetching static data:', error)
        throw error
      })

  } else {


    return request({
      url: '/applications/list',
      method: 'get',
      params
    })
  }
}


// 获取产品分类
export function getCateList() {

  if (process.env.VUE_APP_CONFIG_WAY == 'JSON') {
    let url = baseUrl + 'applicationCategory.json?t=' + new Date().getTime()
    return axios.get(url)
      .then(response => {
        return {
          data: {
            items: response.data
          }
        }
      })
      .catch(error => {
        console.error('Error fetching static data:', error)
        throw error
      })

  } else {


  return request({
    url: `/applications/listCate`,
    method: 'post'
  })}
}
