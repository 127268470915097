
<template>
	<div>
		<vHeader @showSearch="showSearch" class="header"></vHeader>
		<vBanner :showSearch='searchVisible' :pagePath="pagePath" class="page-banner"></vBanner>
		<div class="page-content">

			<div class="result-list">
				<resultItem v-for="i in resultList" v-bind:key="i.id" :item="i"></resultItem>
			</div>
			<div v-if="keyword == ''">
				<p>Please input a keyword to search</p>
			</div>
			<div v-if="keyword != '' && resultList.length == 0">
				<p>No result</p>
			</div>
			<el-pagination v-if="keyword != ''" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-size="pageSize" layout="  prev, pager, next "
				:total="resultList.length"></el-pagination>

		</div>
	</div>
</template>
<style scoped>
.result-list {
	margin-top: 140px;
	margin-bottom: 40px;
}

.page-content {
	padding-bottom: 120px;
}
</style>
<script>
import vBanner from "../Common/banner.vue";
import vHeader from "../Common/header";
import resultItem from "../Common/search/resultItem.vue";


import { getList as getApplicationList } from "@/api/applications";
import { getList as getProductList } from "@/api/products";

export default {
	name: "searchPage",
	components: { vHeader, vBanner, resultItem },

	data() {
		return {
			searchVisible: true,
			pagePath: 'search',
			keyword: '',
			resultList: [],
			currentPage: 1,
			pageSize: 10,
		};
	},
	updated() {

	},
	watch: {
		$route(to, from) {
			console.log(to);
			// console.log(from);
			this.resultList = [];
			this.keyword = this.$route.params.keyword;
			this.search();
		}
	},
	created() {
		this.keyword = this.$route.params.keyword;
		this.search();
	},
	methods: {
		showSearch() {
			this.searchVisible = true;
		},
		async search() {


			if (this.keyword == '') {
				return;
			} else {
				// Clear previous search results
				this.resultList = [];

				// Get data and perform search
				const productData = await getProductList();
				const applicationData = await getApplicationList();

				const products = productData.data.items || [];
				const applications = applicationData.data.items || [];

				const allItems = [...products, ...applications];

				for (const item of allItems) {
					const { id, name, summary, detailPage } = item;
					let source;

					if (products.includes(item)) {
						source = 'Product';
					} else if (applications.includes(item)) {
						source = 'Application';
					}

					const fullItemString = JSON.stringify(item);

					if (this.hasKeyword(fullItemString, this.keyword)) {
						const intro = this.buildIntro(summary, this.keyword);
						const namex = this.buildIntro(name, this.keyword);

						let pageId = id;
						if (source == 'Application') {
							pageId = detailPage.pop();
						}


						this.resultList.push({
							id,
							namex,
							intro,
							source,
							pageId
						});
					}
				}


			}

		},

		hasKeyword(text, keyword) {
			const plainText = text.toLowerCase();
			const lowercaseKeyword = keyword.toLowerCase();

			return plainText.includes(lowercaseKeyword);
		},

		buildIntro(content, keyword) {
			if (content) {
				var intro = content.replace(
					new RegExp(keyword, 'ig'),
					`<strong style="color: red">$&</strong>`
				);
				return intro;
			}

			else
				return ''
		},

		handleSizeChange(val) {
			this.pageSize = val;
		},

		handleCurrentChange(val) {
			this.currentPage = val;
		},
	},
};

</script>
 