<template>
  <div>
    <vHeader @showSearch="showSearch" class="header"></vHeader>

    <!-- <img src="../../assets/service_banner.jpg" class="page-banner" /> -->
    <vBanner
      :showSearch="searchVisible"
      :pagePath="pagePath"
      class="page-banner"
    ></vBanner>
    <div class="page-content">
      <vContent :pagePath="pagePath"></vContent>
    </div>
    <div class="page-content-full">
      <!-- 整个屏幕宽度的部分，目前只在service和about页面中存在 -->
      <vContent :pagePath="'service_quotes'"></vContent>
    </div>
    <div class="page-content-full">
      <!-- 整个屏幕宽度的部分，目前只在service和about页面中存在 -->
      <vContent :pagePath="'service_links'"></vContent>
    </div>

    <!-- <div class="page-content-full">
      <div class="bg" style="background: blue">
        <div class="page-content service-quotes-list">
          <div class="service-quotes-card half-card">
            <h2>Warehousing and Logistics</h2>
            <p>111</p>
          </div>
          <div class="service-quotes-card half-card">
            <h2>Warehousing and Logistics</h2>
            <p>222</p>
          </div>
          <div class="service-quotes-card full-card">
            <h2>Warehousing and Logistics</h2>
            <p>111</p>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="page-content-full">
      <div class="page-content">
        <h1>TEAM & FACILITY</h1>
 
        <div class="service-links-list">
          <div class="service-links-card bg">
            <div class="service-links-card-inner">
              <h2>TEAM</h2>
              <div class="btn-more" @click="goto">LEARN MORE ></div>
            </div>
          </div>

          <div class="service-links-card bg">
            <div class="service-links-card-inner">
              <h2>TEAM</h2>
              <div class="btn-more" @click="goto">LEARN MORE ></div>
            </div>
          </div>

          <div class="service-links-card bg">
            <div class="service-links-card-inner">
              <h2>TEAM</h2>
              <div class="btn-more" @click="goto">LEARN MORE ></div>
            </div>
          </div>
        </div>
      </div>
    </div> -->



  </div>
</template>

<style scoped>
.page-content img {
  margin-bottom: 100px;
  margin-top: 50px;
}

.page-content {
  margin-bottom: 100px;
}

.fake-table {
  width: 100%;
}

.table-tr {
  vertical-align: text-bottom;
  text-align: left;
}

.table-title {
  width: 350px;
  font-size: 24px;
  font-family: Arial;
  font-weight: bold;
  line-height: 40px;
  color: #333333;
  opacity: 1;
}

.table-content {
  font-size: 24px;
  font-family: Arial;
  font-weight: 400;
  line-height: 40px;
  color: #333333;
  opacity: 0.6;
}
</style>

<script>
import vBanner from "../Common/banner.vue";

import vContent from "../Common/content.vue";
import vHeader from "../Common/header";
export default {
  name: "aboutPage",
  props: {},
  data() {
    return {
      searchVisible: false,
      pagePath: "service",
      swiperOptions: {
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // autoplay: true

        // Some Swiper option/callback...
      },
      activeIndex: 1,
    };
  },
  methods: {
    showSearch() {
      this.searchVisible = true;
    },
    // topre() {
    //   this.swiper.slidePrev();
    // },
    // tonext() {
    //   this.swiper.slideNext();
    // }
  },
  components: { vHeader, vContent, vBanner },
  computed: {
    // swiper() {
    //   return this.$refs.mySwiper.$swiper;
    // }
  },
  mounted() {},
};
</script>
