import request from '@/utils/request'
import axios from 'axios'
let baseUrl = `${window.location.protocol}//${window.location.host}/contents/`;

// 获取列表
export function getList(params) {

  if (process.env.VUE_APP_CONFIG_WAY == 'JSON') {
    let url = baseUrl + 'menu.json?t=' + new Date().getTime()
    return axios.get(url)
      .then(response => {
        return {
          data: {
            items: response.data
          }
        }
      })
      .catch(error => {
        console.error('Error fetching static data:', error)
        throw error
      })

  } else {
  return request({
    url: '/menu/list',
    method: 'get',
    params
  })
}
}
