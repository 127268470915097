<template>
	<div class="helper">
		<h3>WE'RE HERE TO HELP</h3>
		<div class="helper-contact">For more inquiries, please contact us now.</div>
		<div class="helper-row3">
			<img src="../../assets/icon_service.png" />

			<div class="btn-more helper-btn" @click="handleClick"> LEAVE MESSAGE</div>
		</div>
		<br>
		<div class="dvider"></div>
		<div class="helper-row4">
			<div class="helper-contact">
				<a target="_blank">
					<i class="el-icon-phone" style="color: white"></i>
					<span style="opacity: 0.66;">Tel：</span><br>
					
					<div style="margin-left:0rem"> <b>{{ tel }}</b></div>
				</a>
			</div>

			<div class="helper-contact">
				<a target="_blank">
					<i class="el-icon-s-promotion" style="color: white"></i>
					<span style="opacity: 0.66;">E-Mail：</span>

					<br>

					<div style="margin-left:0rem"> <b>{{ mail }}</b></div>
				</a>
			</div>
		</div>
	</div>
</template>

<style scoped>
h3 {
	font-size: 1.7rem;
	;
}

.helper {
	width: 300px;
	height: 330px;
	padding: 50px;
	background: #2a8fca;
	box-shadow: 0px 3px 6px 1px rgba(0, 102, 162, 0.2);
	border-radius: 8px 8px 8px 8px;
	color: white;
	text-align: left;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.helper-btn {
	height: 36px;
	width: 200px;
	font-size: 20px;
	line-height: 36px;
	margin: 0;
	text-align: center;
}

.helper h3 {
	padding: 0;
	margin: 0;
	text-align: center;
}

.helper-row4 {
	text-align: left;
}

.helper-contact {
	font-size: 1.3rem;
	margin-top: 15px;
}

.helper-row3 {
	text-align: left;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: flex-start;
	align-items: center;
}

.helper-row3 img {
	height: 30px;
	margin-right: 30px;
}

.helper-contact i {
	margin-right: 10px;
}
</style>

<style  scoped>
/* Extra small devices (phones, less than 768px) */
@media (max-width: 767.98px) {
	/* Custom styles for xs screens */

	.helper {
		height: 400px;
		padding: 50px 30px;
		width: 340px;
	}
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
	/* Custom styles for sm screens */

	.helper {
		height: 400px;
		padding: 50px 30px;
		width: 340px;
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
	/* Custom styles for md screens */
}
</style>


<script>


import { getList } from "@/api/footer.js";
export default {
	name: "vHelper",
	props: {},
	data() {
		return {
			copyright: "",
			tel: "",
			mail: "",
		};
	},
	methods: {
		handleClick() {
			this.$emit('scrollToContactForm');
		},
		async fetchContent() {
			const res = await getList();

			return res;
		},
	},
	computed: {},
	async mounted() {
		let res = await this.fetchContent();
		res.data.forEach((d) => {
			if (d.pagePath == "footer_right") this.copyright = d.content;

			if (d.pagePath == "footer_mail") this.mail = d.content;

			if (d.pagePath == "footer_tel") this.tel = d.content;
		});
	}
};
</script>
<style scoped></style>